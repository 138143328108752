// import React, { useContext } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { TimerContext } from "../TimerContext";
// import "./TaskDetail.css";
// import bgImage from "../Assets/Page2/bg.png";
// import formBgImage from "../Assets/Page2/s.png"; //
// const TaskDetail = () => {
//   const { elapsedTime } = useContext(TimerContext); // Access elapsed time from context
//   const { timeLeft } = useContext(TimerContext);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { task, taskIndex, correctQR } = location.state;
//   console.log("task index:", taskIndex);
//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
//   };

//   return (
//     <div
//       //  className="task-detail-container"
//       style={{
//         backgroundImage: `url(${bgImage})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         minHeight: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         flexDirection: "column",
//         fontFamily: "GTLight",
//       }}
//     >
//       <div className="heading1" style={{ width: "100%",  }}>
//         <p style={{ marginBottom: 10 }}> COUNTDOWN RUNNING</p>
//         <h2 style={{ marginTop: 0, fontWeight: "bold" }}>
//           {formatTime(elapsedTime)}
//         </h2>
//       </div>
//       {/* <h1>{task.taskName}</h1> */}
//       <div
//         className="task-detail"
//         style={{
//           backgroundColor: "rgba(202,46,50,229)",
//           // backgroundImage: `url(${formBgImage})`,
//           // backgroundSize: "cover",
//           // backgroundPosition: "center",
//           padding: "35px",
//           borderRadius: "30px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           width: "70%",
//           maxWidth: "400px",
//           marginTop: -15,
//           flexDirection: "column",
//           height:'50vh',
//           textAlign:'left',
//           marginBottom:20
//         }}
//       >
//         <div style={{ height: '80%', overflowY: "auto" }}>
//           <h3 style={{color:'white',fontWeight:'bold'}}>TASK DESCRIPTION</h3>
//           <p className="task-detail-heading">{task.taskName}</p>
//           <h3 style={{color:'white',fontWeight:'bold'}}>CLUE</h3>
//           <p className="task-detail-clue">{task.clue}</p>
//         </div>
//         <div
//           className="task-detail-buttons"
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             gap: "10px",
//             marginBottom: 20, 
//           }}
//         >
//           <button
//             className="go-back-button1"
//             onClick={() =>
//               navigate("/qr-scanner", {
//                 state: { taskIndex: taskIndex, correctQR },
//               })
//             }
//           >
//             SCAN QR CODE
//           </button>
//           <button
//             className="go-back-button1"
//             onClick={() => navigate("/treasure")}
//           >
//             GO BACK
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TaskDetail;









import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TimerContext } from "../TimerContext";
import "./TaskDetail.css";
import bgImage from "../Assets/Page2/bg.jpg";

const TaskDetail = () => {
  const { elapsedTime } = useContext(TimerContext);
  const { timeLeft } = useContext(TimerContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { task, taskIndex, correctQR } = location.state;
  var backgroundColor = "rgba(196, 22, 28, 0.9)";
  const taskColors = [
    "rgba(196, 22, 28, 0.9)",
    "rgba(72,102,150,0.9)",
    "rgba(216,186,85,0.9)",
    "rgba(151,159,113,0.9)",
    "rgba(116,179,224,0.9)",
    "rgba(194,122,160,0.9)",
  ];
  backgroundColor = taskColors[taskIndex];
  console.log("task index:", taskIndex);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
  
    return hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
  };
  

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontFamily: "GTLight",
      }}
    >
      <div className="heading1" style={{ width: "100%" }}>
        <p style={{ marginBottom: 10 }}> COUNTDOWN RUNNING</p>
        <h2 style={{ marginTop: 0, fontWeight: "bold" }}>
          {formatTime(elapsedTime)}
        </h2>
      </div>
      <div
        className="task-detail"
        style={{
          backgroundColor: backgroundColor,
          padding: "35px",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "70%",
          maxWidth: "400px",
          marginTop: -15,
          flexDirection: "column",
          height: "50vh",
          textAlign: "left",
          marginBottom: 20,
        }}
      >
        <div style={{ height: "80%", overflowY: "auto" }}>
          <h3 style={{ color: "white", fontWeight: "bold" }}>
            TASK DESCRIPTION
          </h3>
          <p className="task-detail-heading">{task.taskName}</p>
          <h3 style={{ color: "white", fontWeight: "bold" }}>CLUE</h3>
          <p className="task-detail-clue">{task.clue}</p>
        </div>
        <div
          className="task-detail-buttons"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: 20,
          }}
        >
          {task.status !== "Completed" && ( // Check task status
            <button
              className="go-back-button1"
              onClick={() =>
                navigate("/qr-scanner", {
                  state: { taskIndex: taskIndex, correctQR },
                })
              }
            >
              SCAN QR CODE
            </button>
          )}
          <button
            className="go-back-button1"
            onClick={() => navigate("/treasure")}
          >
            GO BACK
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskDetail;
