import React, { useState } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import bgImage from "../Assets/Page2/bg.jpg"; // 
import formBgImage from "../Assets/Page2/s.png"; // 

const db = getFirestore();

const OtpVerification = ({ email, navigateToResetPassword }) => {
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const userDoc = await getDoc(doc(db, "users", email));
      if (userDoc.exists() && userDoc.data().otp === otp) {
        setMessage("OTP Verified!");
        navigateToResetPassword(email);
      } else {
        setMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setMessage("Error verifying OTP. Please try again.");
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form
        style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          padding: "20px",
          borderRadius: "10px",
          width: "100%",
          maxWidth: "400px",
        }}
        onSubmit={handleVerifyOtp}
      >
        <h2 style={{ color: "white", textAlign: "center" }}>Verify OTP</h2>
        {message && <p style={{ color: "lightgreen", textAlign: "center" }}>{message}</p>}
        <input
          type="text"
          placeholder="Enter OTP"
          style={inputStyle}
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          required
        />
        <button
          type="submit"
          style={{
            background: "white",
            color: "red",
            border: "none",
            borderRadius: "5px",
            padding: "10px 15px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "100%",
          }}
        >
          Verify OTP
        </button>
      </form>
    </div>
  );
};


const inputStyle = {
    width: "80%",
    padding: "10px",
    marginBottom: "15px",
    border: "1px solid white",
    borderRadius: "5px",
    backgroundColor: "transparent",
    color: "white",
    outline: "none",
  };
export default OtpVerification;
