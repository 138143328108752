import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import toast from "react-hot-toast";
import bgImage from "../Assets/Page2/bg.jpg";
import formBgImage from "../Assets/Page2/s.png";
import "./TermsAndConditions.css";

const db = getFirestore();

const TermsAndConditions = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleNext = async () => {
    if (!isChecked) {
      toast.error("You must agree to the terms and conditions to proceed.");
      return;
    }

    setError("");

    const storedEmail = localStorage.getItem("email");
    if (!storedEmail) {
      // toast.error("User email not found in localStorage.");
      return;
    }

    try {
      // Query Firestore to find the user by email
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", storedEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Update the termsChecked field for the matching document
        const userDoc = querySnapshot.docs[0];
        await updateDoc(userDoc.ref, { termsChecked: true });

        // toast.success("Terms and conditions accepted successfully.");
        navigate("/dashboard");
      } else {
        // toast.error("No user found with the given email.");
      }
    } catch (error) {
      console.error("Error updating Firestore: ", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setError("");
    }
  };

  return (
    <div
      className="terms-container"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "GTLight",
      }}
    >
      <div
        className="terms-box"
        style={{
          // backgroundImage: `url(${formBgImage})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          backgroundColor: "rgba(202,46,50,229)",
          padding: "40px",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "75%",
          textAlign: "left",
          height: "65vh",
        }}
      >
        <h3
          style={{
            textAlign: "left",
            marginBottom: "20px",
            marginBottom: "10px",
          }}
        >
          TERMS & CONDITIONS
        </h3>

        <div className="terms-content" style={{ marginBottom: "20px" }}>
          <p>These are the terms and conditions that govern your relationship with Merry Mystery Treasure Hunt, in your capacity as a participant in this Event or otherwise. Please read these terms and conditions carefully before agreeing to participate, volunteer, or otherwise be engaged in this Event, as it contains your rights and obligations. By continuing with this Event, you expressly agree to the terms and conditions below:</p>
          <h4>1. Definitions</h4>
          <p>1.1 "MMTH" means the "Merry Mystery hunt," and includes reference to the organizers of the Event, beneficial owners including Wafi Property, affiliates, officers, employees, agents, contractors, insurers, spectators, equipment suppliers, volunteers, and any persons in any way engaged by or related to the Event.</p>
          <h4>2. Eligibility</h4>
          <p>This event is open to all participants, except Wafi Group employees, Wafi City tenants, and shop staff, who are not entitled to participate in this promotion/game.</p>
          <h4>3. General Rules</h4>
          <p>3.1 I understand that each registration is a solo registration.</p>
          <p>3.2 The hunt is conducted on a leaderboard system. The player with the minimum time to finish the hunt will be on top.</p>
          <p>3.3 The Treasure Hunt will be facilitated using a web-based application. In case of a technological failure, please contact Wafi or visit the MMTH registration stand.</p>
          <p>3.4 I agree to become familiar with and abide by all written and/or posted rules of MMTH. I further agree to comply with all directions, instructions, and decisions of MMTH and venue personnel. I further agree not to challenge these rules, directions, instructions, or decisions on any basis at any time.</p>
          <p>3.5 I understand that I need to be physically present at all locations while participating and solving the challenges in the MMTH.</p>
          <p>3.6 I understand prizes won cannot be exchanged or refunded for cash in part or full.</p>
          <p>3.7 I understand prizes won cannot be changed or transferred.</p>
          <p>3.8 I agree customer data collected during participation will be used for future communications purposes.</p>
          <h4>4. Waiver of Liability for Negligence</h4>
          <p>4.1 To the extent permitted by law, I agree that under these terms and conditions, MMTH shall be excluded from liability for negligence in the conduct of the Event, and further, MMTH shall be excluded from liability under any civil liability or consumer law, or any arbitration order, applicable to the Emirate of Dubai in which this Event is held, subject to the exception that this exclusion of liability will not apply in circumstances where the conduct of MMTH or its officers, servants, or agents is proven to be grossly negligent.</p>
          <h4>5. Indemnification Agreement</h4>
          <p>5.1 I hereby agree to hold harmless, defend, and indemnify MMTH, which for the avoidance of doubt includes defending and paying any judgment, court costs, arbitration costs, expenses, and claims, investigation costs, attorney's fees, and any other expenses incurred that relate to a breach of these terms and conditions from any and all claims made by me, or any Releasing Party, arising from death, physical or mental injury, including the aggravation, acceleration, or occurrence of such an injury, the contraction, aggravation, or acceleration of a disease, or the coming into existence, the aggravation, acceleration, or occurrence of any other condition, circumstance, occurrence, activity, form of behavior, course of conduct, or state of affairs in relation to an individual that is or may be harmful or disadvantageous to me or the community or that may result in harm or disadvantage to me or the community due to my participation, volunteering, or otherwise being involved in the Event, including by way of any such harm, disadvantage, injury caused to the Releasing Party during training or preparing for the event, be it at the Venue, at any participating partners of MMTH or the Event, or at any other third-party venue. This applies both to claims arising from the inherent risks of the Event or any act of MMTH.</p>
          <p>5.2 I further agree to hold harmless, defend, and indemnify (which for the avoidance of doubt includes defending and paying any judgment, court costs, investigation costs, attorney's fees, and any other expenses incurred that relate to a breach of this contract) MMTH (and the other Released Parties) against any and all claims of co-participants, rescuers, and others arising from my conduct in the course of my participation, volunteering, or otherwise being involved in the Event. I hereby indemnify and hold harmless MMTH against any claims.</p>
          <h4>DECLARATION </h4>
          <p>I HEREBY FULLY AND EXPRESSLY CONFIRM THAT BY REGISTERING FOR THE EVENT, I AGREE AND ACCEPT THESE TERMS AND CONDITIONS AND IRREVOCABLY AGREE TO BE FULLY BOUND BY THE SAME. I FURTHER CONFIRM THAT WHERE I HAVE REGISTERED FOR THE EVENT FOR ANOTHER PERSON, THEN SUCH PERSON HAS READ AND ACCEPTED THESE TERMS AND CONDITIONS AS WELL AND HAS IRREVOCABLY AGREED TO BE FULLY BOUND BY THE SAME.</p>
        </div>

        <div
          className="terms-actions"
          style={{
            display: "flex", // Ensures flexbox layout
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              style={{ marginRight: "10px", accentColor: "black" }}
            />
            I agree to these terms and conditions
          </label>
          {error && (
            <p style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>
              {error}
            </p>
          )}
        </div>
        <button
          onClick={handleNext}
          type="submit"
          style={{
            background: "white",
            color: "red",
            border: "none",
            borderRadius: "15px",
            padding: "10px 15px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "100%",
            marginBottom: "20%",
            marginTop: 5,
            alignSelf: "center",
          }}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default TermsAndConditions;
