







// import React, { useState, useEffect } from 'react';
// import { collection, getDocs, addDoc, deleteDoc, doc, getFirestore } from 'firebase/firestore';
// import Modal from 'react-modal';
// import './reward.css'

// const db = getFirestore(); // Import your Firebase config and Firestore initialization

// export default function AdminRewards() {
//   const [rewards, setRewards] = useState([]);
//   const [pointsGreaterThan, setPointsGreaterThan] = useState('');
//   const [pointsLessThan, setPointsLessThan] = useState('');
//   const [reward, setReward] = useState('');
//   const [error, setError] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // Fetch rewards from Firestore
//   useEffect(() => {
//     const fetchRewards = async () => {
//       try {
//         const rewardsCollection = collection(db, 'rewards');
//         const rewardsSnapshot = await getDocs(rewardsCollection);
//         const rewardsList = rewardsSnapshot.docs.map(doc => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setRewards(rewardsList);
//       } catch (err) {
//         setError('Failed to fetch rewards');
//         console.error(err);
//       }
//     };

//     fetchRewards();
//   }, []);

//   // Add a new reward
//   const handleAddReward = async () => {
//     if (pointsGreaterThan && pointsLessThan && reward) {
//       try {
//         await addDoc(collection(db, 'rewards'), {
//           pointsGreaterThan: parseInt(pointsGreaterThan, 10),
//           pointsLessThan: parseInt(pointsLessThan, 10),
//           reward,
//         });
//         setPointsGreaterThan('');
//         setPointsLessThan('');
//         setReward('');
//         setIsModalOpen(false); // Close modal after adding reward
//         // Refresh rewards list
//         const rewardsCollection = collection(db, 'rewards');
//         const rewardsSnapshot = await getDocs(rewardsCollection);
//         const rewardsList = rewardsSnapshot.docs.map(doc => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setRewards(rewardsList);
//       } catch (err) {
//         setError('Failed to add reward');
//         console.error(err);
//       }
//     } else {
//       setError('Please fill in all fields');
//     }
//   };

//   // Delete a reward
//   const handleDeleteReward = async (id) => {
//     if (window.confirm('Are you sure you want to delete this reward?')) {
//       try {
//         const rewardDoc = doc(db, 'rewards', id);
//         await deleteDoc(rewardDoc);
//         // Refresh rewards list after deletion
//         setRewards(rewards.filter((reward) => reward.id !== id));
//       } catch (err) {
//         setError('Failed to delete reward');
//         console.error(err);
//       }
//     }
//   };

//   return (
//     <div className="container">
//       {/* Error message */}
//       {error && <p className="error-message">{error}</p>}

//       {/* Modal for adding new reward */}
//       <Modal
//         isOpen={isModalOpen}
//         onRequestClose={() => setIsModalOpen(false)}
//         contentLabel="Add Reward"
//         ariaHideApp={false}
//         className="modal-content"
//       >
//         <h2>Add New Reward</h2>
//         <label>
//           Points Greater Than:
//           <input
//             type="number"
//             value={pointsGreaterThan}
//             className="input-field"
//             onChange={(e) => setPointsGreaterThan(e.target.value)}
//           />
//         </label>
//         <label>
//           Points Less Than:
//           <input
//             type="number"
//             value={pointsLessThan}
//             className="input-field"
//             onChange={(e) => setPointsLessThan(e.target.value)}
//           />
//         </label>
//         <label>
//           Reward:
//           <input
//             type="text"
//             value={reward}
//             className="input-field"
//             onChange={(e) => setReward(e.target.value)}
//           />
//         </label>
//         <div className="modal-buttons">
//           <button onClick={handleAddReward} className="btn-primary">Add Reward</button>
//           <button onClick={() => setIsModalOpen(false)} className="btn-secondary">Close</button>
//         </div>
//       </Modal>

//       <div className="rewards-container">
//         <h1>Rewards Management</h1>
//         <h2>Existing Rewards</h2>

//         <div className="create-reward-button-container">
//           <button onClick={() => setIsModalOpen(true)} className="btn-primary">Create New Reward</button>
//         </div>

//         {rewards.length > 0 ? (
//           <table className="rewards-table">
//             <thead>
//               <tr>
//                 <th>#</th>
//                 <th>Points Greater Than</th>
//                 <th>Points Less Than</th>
//                 <th>Reward</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {rewards.map((rewardItem, index) => (
//                 <tr key={rewardItem.id}>
//                   <td>{index + 1}</td>
//                   <td>{rewardItem.pointsGreaterThan}</td>
//                   <td>{rewardItem.pointsLessThan}</td>
//                   <td>{rewardItem.reward}</td>
//                   <td>
//                     <button onClick={() => handleDeleteReward(rewardItem.id)} className="btn-danger">
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <p>No rewards found</p>
//         )}
//       </div>
//     </div>
//   );
// }





























import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, getFirestore } from 'firebase/firestore';
import Modal from 'react-modal';
import './reward.css'

const db = getFirestore(); // Import your Firebase config and Firestore initialization

export default function AdminRewards() {
  const [rewards, setRewards] = useState([]);
  const [pointsGreaterThan, setPointsGreaterThan] = useState('');
  const [pointsLessThan, setPointsLessThan] = useState('');
  const [reward, setReward] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleGoBack = () => {
    window.history.back();
  };

  // Fetch rewards from Firestore
  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const rewardsCollection = collection(db, 'rewards');
        const rewardsSnapshot = await getDocs(rewardsCollection);
        const rewardsList = rewardsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRewards(rewardsList);
      } catch (err) {
        setError('Failed to fetch rewards');
        console.error(err);
      }
    };

    fetchRewards();
  }, []);

  // Add a new reward
// Add a new reward
const handleAddReward = async () => {
  if (pointsGreaterThan && pointsLessThan && reward) {
    try {
      // Modify to use 'greater than or equal to' logic
      await addDoc(collection(db, 'rewards'), {
        pointsGreaterThan: parseInt(pointsGreaterThan, 10),
        pointsLessThan: parseInt(pointsLessThan, 10),
        reward,
      });
      setPointsGreaterThan('');
      setPointsLessThan('');
      setReward('');
      setIsModalOpen(false); // Close modal after adding reward
      // Refresh rewards list
      const rewardsCollection = collection(db, 'rewards');
      const rewardsSnapshot = await getDocs(rewardsCollection);
      const rewardsList = rewardsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRewards(rewardsList);
    } catch (err) {
      setError('Failed to add reward');
      console.error(err);
    }
  } else {
    setError('Please fill in all fields');
  }
};

  // Delete a reward
  const handleDeleteReward = async (id) => {
    if (window.confirm('Are you sure you want to delete this reward?')) {
      try {
        const rewardDoc = doc(db, 'rewards', id);
        await deleteDoc(rewardDoc);
        // Refresh rewards list after deletion
        setRewards(rewards.filter((reward) => reward.id !== id));
      } catch (err) {
        setError('Failed to delete reward');
        console.error(err);
      }
    }
  };

  return (
    <div className="container">
      {/* Error message */}
      {error && <p className="error-message">{error}</p>}

      {/* Modal for adding new reward */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Add Reward"
        ariaHideApp={false}
        className="modal-content"
      >
        <h2>Add New Reward</h2>
        <label>
  Points Greater Than or Equal To:
  <input
    type="number"
    value={pointsGreaterThan}
    className="input-field"
    onChange={(e) => setPointsGreaterThan(e.target.value)}
  />
</label>

        <label>
          Points Less Than:
          <input
            type="number"
            value={pointsLessThan}
            className="input-field"
            onChange={(e) => setPointsLessThan(e.target.value)}
          />
        </label>
        <label>
          Reward:
          <input
            type="text"
            value={reward}
            className="input-field"
            onChange={(e) => setReward(e.target.value)}
          />
        </label>
        <div className="modal-buttons">
          <button onClick={handleAddReward} className="btn-primary">Add Reward</button>
          <button onClick={() => setIsModalOpen(false)} className="btn-secondary">Close</button>
        </div>
      </Modal>

      <div className="rewards-container">
        <h1>Rewards Management</h1>
        <h2>Existing Rewards</h2>

        <div className="create-reward-button-container">
          <button onClick={() => setIsModalOpen(true)} className="btn-primary">Create New Reward</button>
        </div>

        {rewards.length > 0 ? (
          <table className="rewards-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Points Greater Than</th>
                <th>Points Less Than</th>
                <th>Reward</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
  {rewards.map((rewardItem, index) => (
    <tr key={rewardItem.id}>
      <td>{index + 1}</td>
      <td>{`${rewardItem.pointsGreaterThan} or greater`}</td> {/* Updated display */}
      <td>{rewardItem.pointsLessThan}</td>
      <td>{rewardItem.reward}</td>
      <td>
        <button onClick={() => handleDeleteReward(rewardItem.id)} className="btn-danger">
          Delete
        </button>
      </td>
    </tr>
  ))}
</tbody>

          </table>
        ) : (
          <p>No rewards found</p>
        )}
      </div>
      <button className="go-back-button" style={{ marginTop:20, backgroundColor:'red',color:'white'}} onClick={handleGoBack}>
            GO BACK
          </button>
    </div>
  );
}
