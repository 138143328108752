// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getFirestore, collection, addDoc } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

// // Your web app's Firebase configuration

// const firebaseConfig = {
//     apiKey: "AIzaSyCucXJbKLH6nkZKiuEX09HRFyhK_9-QsvA",
//     authDomain: "treasurehunt-8a57e.firebaseapp.com",
//     projectId: "treasurehunt-8a57e",
//     storageBucket: "treasurehunt-8a57e.firebasestorage.app",
//     messagingSenderId: "743970891130",
//     appId: "1:743970891130:web:c5949c91db60cf447022c3",
//     measurementId: "G-B56T3FGME0"
//   };


// const app = initializeApp(firebaseConfig);


// const analytics = getAnalytics(app);


// const db = getFirestore(app);


// const userCollection = collection(db, "Mubadala_feedbackData");

// // Function to post feedback data to Firestore
// async function postFeedbackData({
//   groupProcurementOrganization,
//   sustainableProcurement,
//   futureProofingProcurement,
//   adaptionToAI,
//   feedbackText,
//   email,
// }) {
//   try {
//     await addDoc(userCollection, {
//       groupProcurementOrganization,
//       sustainableProcurement,
//       futureProofingProcurement,
//       adaptionToAI,
//       feedbackText,
//       email,
//       timestamp: new Date(), // Add timestamp
//     });
//     console.log("Feedback saved successfully");
//   } catch (error) {
//     console.error("Error saving feedback: ", error);
//   }
// }

// export { postFeedbackData };














// Import the functions you need from the SDKs
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { query, where, getDocs, updateDoc, doc } from "firebase/firestore";


// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCucXJbKLH6nkZKiuEX09HRFyhK_9-QsvA",
  authDomain: "treasurehunt-8a57e.firebaseapp.com",
  projectId: "treasurehunt-8a57e",
  storageBucket: "treasurehunt-8a57e.firebasestorage.app",
  messagingSenderId: "743970891130",
  appId: "1:743970891130:web:c5949c91db60cf447022c3",
  measurementId: "G-B56T3FGME0",
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const userCollection = collection(db, "users");



async function postUserData({ name, email, mobileNumber, nationality, password,surname }) {
  const db = getFirestore(); // Initialize Firestore
  const userCollection = collection(db, "users"); // Reference to the "users" collection

  try {
    // Check if a user with the same email already exists
    const userQuery = query(userCollection, where("email", "==", email));
    const querySnapshot = await getDocs(userQuery);
  

    if (!querySnapshot.empty) {
      // Email already exists in Firestore
      console.error("Error: Email already exists");
      return { success: false, message: "Email already exists" };
    }

    // Add new user to Firestore
    await addDoc(userCollection, {
      name,
      surname,
      email,
      mobileNumber,
      nationality,
      password,
      attempts: 0,
      createdAt: new Date(),
      lastLogin: new Date(), // Initialize with the current date
    });

    console.log("User data saved successfully");
    return { success: true, message: "User data saved successfully" };
  } catch (error) {
    console.error("Error saving user data: ", error);
    return { success: false, message: "Error saving user data" };
  }
}

  async function updateUserScore(email, scoreToAdd) {
    try {
   
      const userQuery = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(userQuery);
  
      if (!querySnapshot.empty) {
      
        const userDoc = querySnapshot.docs[0];
        const userRef = doc(db, "users", userDoc.id);
  
        const userData = userDoc.data();
        const currentAttempts = userData.attempts || 0; 
  
        if (currentAttempts <= 1) {
      
      
          const currentScore = userData.score || 0; 
          const newScore = currentScore + scoreToAdd;
  
          await updateDoc(userRef, {  score: newScore });
  
          console.log(
            `Attempts incremented and score updated successfully. New Score: ${newScore}`
          );
        } else {
          console.log("Maximum attempts reached. Score not updated.");
        }
      } else {
        console.error("User not found with the given email.");
      }
    } catch (error) {
      console.error("Error updating user score: ", error);
    }
  }
  


  async function updateUserAttempts(email) {
    try {
      // Query the 'users' collection to find the document with the matching email
      const userQuery = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(userQuery);
  
      if (!querySnapshot.empty) {
        // Get the first matching document
        const userDoc = querySnapshot.docs[0];
        const userRef = doc(db, "users", userDoc.id);
  
        const userData = userDoc.data();
        const currentAttempts = userData.attempts || 0; // Default to 0 if no attempts field
  
        if (currentAttempts < 1) {
     
          const newAttempts = currentAttempts + 1;
   
       
  
          await updateDoc(userRef, { attempts: newAttempts});
  
          console.log(
            `Attempts incremented and score updated successfully. New Attempts: ${newAttempts},`
          );
        } else {
          console.log("Maximum attempts reached. Score not updated.");
        }
      } else {
        console.error("User not found with the given email.");
      }
    } catch (error) {
      console.error("Error updating user attempt: ", error);
    }
  }

  async function getUserAttempt(email) {
    try {
      // Query the 'users' collection to find the document with the matching email
      const userQuery = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(userQuery);
  
      if (!querySnapshot.empty) {
        // Get the first matching document
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
  
        return userData.attempts || 0; // Return the attempts value, default to 0 if not present
      } else {
        console.error("User not found with the given email.");
        return null;
      }
    } catch (error) {
      console.error("Error retrieving user attempts: ", error);
      return null;
    }
  }



  async function resetAttemptsOnLogin(email) {
    try {
      const userQuery = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(userQuery);
  
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userRef = doc(db, "users", userDoc.id);
        const userData = userDoc.data();
  
        const lastLogin = userData.lastLogin ? userData.lastLogin.toDate() : null; // Convert Firestore timestamp
        const currentDate = new Date();
        const isNewDay = lastLogin
          ? lastLogin.toDateString() !== currentDate.toDateString()
          : true;
  
        if (isNewDay) {
          await updateDoc(userRef, {
            attempts: 0,
            lastLogin: currentDate, // Update last login date
          });
          console.log("Attempts reset to 0 for a new day.");
        } else {
          console.log("User has already logged in today.");
        }
      } else {
        console.error("User not found with the given email.");
      }
    } catch (error) {
      console.error("Error resetting attempts on login: ", error);
    }
  }
  






  async function updateElapsedTime(email, formattedTime) {
    try {
      // Query to find the user by email
      const userQuery = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(userQuery);
  
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userRef = doc(db, "users", userDoc.id);
  
        // Update the Firestore document with the formatted time
        await updateDoc(userRef, {
          elapsedTime: formattedTime, // Saves MM:SS or HH:MM:SS
        });
  
        console.log(`Elapsed time updated successfully: ${formattedTime}`);
      } else {
        console.error("User not found with the given email.");
      }
    } catch (error) {
      console.error("Error updating elapsed time: ", error);
    }
  }
  export { postUserData ,updateUserScore,getUserAttempt,updateUserAttempts,resetAttemptsOnLogin,updateElapsedTime};
  

