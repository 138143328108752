import React,{useContext, useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import bgImage from "../Assets/Page2/btn.png"; 
import { TimerContext } from '../TimerContext';
import {updateUserAttempts,getUserAttempt} from '../firebase'
import { useLocation } from "react-router-dom";

import bg from "../Assets/Page2/bg.jpg"; 

const Thankyou = () => {
  const navigate = useNavigate();
  const { startTimer, timeLeft, stopTimer } = useContext(TimerContext);
  const [buttonText, setButtonText] = useState('')
  const [userAttempt, setUserAttempt] = useState(0)
  const location = useLocation();
   const { elapsedTime } = location.state;
  //  console.log('score',scoreToAdd);
  
 const handleStopTimer=()=>{
  localStorage.removeItem('email')
  stopTimer()
 }
  useEffect(()=>{
         const getAttempts =async()=>{
           const userEmail = localStorage.getItem("email"); 
                  const attempts = await getUserAttempt(userEmail);
                  setUserAttempt(attempts);
                  if(attempts==0)
                  {
                   setButtonText('Start Treasure Hunting')
                  }
               
                  else {
                   console.log('attempts reached');
                  }
         }
         getAttempts();
   },[])
 
 
 

  const handleStartTimer = () => {

    startTimer();
    const userEmail = localStorage.getItem("email"); 
    updateUserAttempts(userEmail);
    localStorage.removeItem("randomTasks");
    localStorage.removeItem("taskStatuses");
  };
  const handleButtonClick = (route) => {
    navigate(route); 
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:'column',
        fontFamily:'GTLight',
        textTransform: 'capitalize'
        
      }}
    >
<div style={{width:'90%',color:'white', fontFamily:'GTRegular'}}>
    <h2 >Congratulations! </h2>
    <h3>You have completed all tasks in Time :</h3>
    <h2>{elapsedTime} </h2>
    <h3>Please proceed to the prize station for your reward</h3>
    </div>

    {userAttempt<=0 ?(<>

<button
      onClick={() => {handleButtonClick("/treasure")
        handleStartTimer();
      }}
      style={buttonStyle}
    >
   {buttonText}
    </button>
</>):(<></>)}
  
      <button
        onClick={() => handleButtonClick("/leaderboard")}
        style={buttonStyle}
      >
       LEADERBOARD
      </button>

      <button
        onClick={() => handleButtonClick("/profile")}
        style={buttonStyle}
      >
       MY PROFILE
      </button>

      {/* <button
        onClick={() => handleButtonClick("/rewards")}
        style={buttonStyle}
      >
     REWARDS
      </button> */}

      <button
        onClick={() => {
          localStorage.removeItem("userSession");
          localStorage.removeItem("email");
          localStorage.removeItem("randomTasks");
          localStorage.removeItem("taskStatuses");
          localStorage.removeItem("timeLeft"); 

          localStorage.removeItem('startTime');
          handleStopTimer();
          navigate("/login");
        }}
        style={buttonStyle}
      >
      SIGN OUT
      </button>
    </div>
  );
};

const buttonStyle = {
    background: "rgba(196, 22, 28, 0.9)", 
    color: "white",
    border: "none",
    padding: "15px 30px",
    margin: "10px 0",
    fontSize: "18px",
    fontWeight: "bold",
    width: "80%",
    maxWidth: "300px",
    borderRadius: "10px",
    cursor: "pointer",
    transition: "transform 0.3s ease, opacity 0.3s ease",
    fontFamily:'GTLight'
  };
export default Thankyou;

