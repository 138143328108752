// import React, { useEffect, useState } from "react";
// import { initializeApp } from "firebase/app";
// import { getFirestore, collection, query, orderBy, limit, getDocs } from "firebase/firestore";
// import "./Leaderboard.css"; // Import the CSS for styling


// const db = getFirestore();
//   const handleGoBack = () => {
//     window.history.back();
//   };
// const Leaderboard = () => {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     const fetchLeaderboard = async () => {
//       try {
//         const leaderboardQuery = query(
//           collection(db, "users"),
//           orderBy("score", "desc"),
//           limit(10)
//         );
//         const querySnapshot = await getDocs(leaderboardQuery);
//         const leaderboardData = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setUsers(leaderboardData);
//       } catch (error) {
//         console.error("Error fetching leaderboard: ", error);
//       }
//     };

//     fetchLeaderboard();
//   }, []);

//   return (
//     <div className="leaderboard-container">
//     <div className="leaderboard">
//       {/* Vertical Lines Covering Full Height */}
//       <div className="vertical-line left"></div>
//       <div className="vertical-line right"></div>

//       <h2 style={{ textAlign: "center", color: "white" }}>LEADERBOARD</h2>
//       <div className="leaderboard-header">
//         <span>Rank</span>
//         <span>Name</span>
//         <span>Points</span>
//       </div>

//       {users.map((user, index) => (
//         <div key={user.id} className="leaderboard-row">
//           <span>{index + 1}</span>
//           <span style={{ textAlign: "left", marginLeft: "20px" }}>
//             {user.name || "Player Name"}
//           </span>
//           <span>{user.score || 0}</span>
//         </div>
//       ))}

//       <button className="go-back-button" onClick={handleGoBack}>
//         GO BACK
//       </button>
//     </div>
//   </div>
//     // <div className="leaderboard-container" style={{flexDirection:'column', fontFamily:'GTRegular'}}>
//     //         <h2 style={{color:'white'}}>LEADERBOARD</h2>
//     //   <div className="leaderboard">

//     //     <div className="leaderboard-header">
//     //       <span>Rank</span>
//     //       <span>Name</span>
//     //       <span>Points</span>
//     //     </div>
//     //     {users.map((user, index) => (
//     //       <div key={user.id} className="leaderboard-row">
//     //         <span>{index + 1}</span>
//     //         <span>{user.name || "Player Name"}</span>
//     //         <span>{user.score || 0}</span>
//     //       </div>
//     //     ))}
//     //   </div>
//     // </div>
//   );
// };

// export default Leaderboard;



















// import React, { useEffect, useState } from "react";
// import { getFirestore, collection, query, orderBy, limit, getDocs } from "firebase/firestore";
// import "./Leaderboard.css"; // Import the CSS for styling

// const db = getFirestore();


// const Leaderboard = () => {
//   const [users, setUsers] = useState([]);

//   const handleGoBack = () => {
//     window.history.back();
//   };

//   useEffect(() => {
//     const fetchLeaderboard = async () => {
//       try {
//         const leaderboardQuery = query(
//           collection(db, "users"),
//           orderBy("score", "desc"),
//           limit(10)
//         );
//         const querySnapshot = await getDocs(leaderboardQuery);
//         const leaderboardData = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));

//         // Optionally, sort users on the frontend (in case Firestore query doesn't order as expected)
//         leaderboardData.sort((a, b) => b.score - a.score); // Sort by score in descending order

//         setUsers(leaderboardData);
//       } catch (error) {
//         console.error("Error fetching leaderboard: ", error);
//       }
//     };

//     fetchLeaderboard();
//   }, []);

//   return (
//     <div className="leaderboard-container" style={{ flexDirection: "column" }}>
//   <h2 style={{ textAlign: "center", color: "white" }}>LEADERBOARD</h2>
//   <div className="leaderboard">
//     {/* Vertical Lines Covering Full Height */}
//     <div className="vertical-line left"></div>
//     <div className="vertical-line right"></div>

//     <div className="leaderboard-header">
//       <span>Rank</span>
//       <span>Name</span>
//       <span>Points</span>
//     </div>

//     {users.map((user, index) => (
//       <div key={user.id} className="leaderboard-row">
//         <span>{index + 1}</span>
//         <span>{user.name || "Player Name"}</span>
//         <span>{user.score || 0}</span>
//       </div>
//     ))}
//   </div>
//   <button
//     className="go-back-button"
//     style={{
//       marginTop: 20,
//       backgroundColor: "rgba(202,46,50,229)",
//       width: "80%",
//       color: "white",
//       borderRadius:30
//     }}
//     onClick={handleGoBack}
//   >
//     GO BACK
//   </button>
// </div>


//   );
// };

// export default Leaderboard;











// import React, { useEffect, useState } from "react";
// import { getFirestore, collection, query, orderBy, limit, getDocs } from "firebase/firestore";
// import "./Leaderboard.css"; // Import the CSS for styling

// const db = getFirestore();

// const Leaderboard = () => {
//   const [users, setUsers] = useState([]);

//   const handleGoBack = () => {
//     window.history.back();
//   };

//   useEffect(() => {
//     const fetchLeaderboard = async () => {
//       try {
//         const leaderboardQuery = query(
//           collection(db, "users"),
//           orderBy("elapsedTime", "asc"), // Order by elapsedTime in ascending order
//           limit(10)
//         );
//         const querySnapshot = await getDocs(leaderboardQuery);

//         let leaderboardData = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));

//         // Sort alphabetically by name if elapsedTime is the same
//         leaderboardData = leaderboardData.sort((a, b) => {
//           if (a.elapsedTime === b.elapsedTime) {
//             return a.name.localeCompare(b.name); // Alphabetical order for names
//           }
//           return a.elapsedTime.localeCompare(b.elapsedTime); // Sort by elapsedTime
//         });

//         setUsers(leaderboardData);
//       } catch (error) {
//         console.error("Error fetching leaderboard: ", error);
//       }
//     };

//     fetchLeaderboard();
//   }, []);

//   return (
//     <div className="leaderboard-container" style={{ flexDirection: "column" }}>
//       <h2 style={{ textAlign: "center", color: "white" }}>LEADERBOARD</h2>
//       <div className="leaderboard">
//         {/* Vertical Lines Covering Full Height */}
//         <div className="vertical-line left"></div>
//         <div className="vertical-line right"></div>

//         <div className="leaderboard-header">
//           <span>Rank</span>
//           <span>Name</span>
//           <span>Time</span>
//         </div>

//         {users.map((user, index) => (
//           <div key={user.id} className="leaderboard-row">
//             <span>{index + 1}</span>
//             <span>{user.name || "Player Name"}</span>
//             <span>{user.elapsedTime || "00:00"}</span>
//           </div>
//         ))}
//       </div>
//       <button
//         className="go-back-button"
//         style={{
//           marginTop: 20,
//           backgroundColor: "rgba(202,46,50,229)",
//           width: "80%",
//           color: "white",
//           borderRadius: 30,
//         }}
//         onClick={handleGoBack}
//       >
//         GO BACK
//       </button>
//     </div>
//   );
// };

// export default Leaderboard;












import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import "./Leaderboard.css";

const db = getFirestore();

// Helper function to convert time to seconds for comparison
const timeToSeconds = (time) => {
  const parts = time.split(":").map(Number);
  if (parts.length === 2) {
    // Format: MM:SS
    return parts[0] * 60 + parts[1];
  } else if (parts.length === 3) {
    // Format: HH:MM:SS
    return parts[0] * 3600 + parts[1] * 60 + parts[2];
  }
  return 0; // Fallback for invalid time
};

const Leaderboard = () => {
  const [users, setUsers] = useState([]);

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const leaderboardQuery = query(
          collection(db, "users"),
          orderBy("elapsedTime", "asc"), // Order by elapsedTime in ascending order
          limit(10)
        );
        const querySnapshot = await getDocs(leaderboardQuery);

        let leaderboardData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort by elapsedTime using seconds and alphabetically if times are equal
        leaderboardData = leaderboardData.sort((a, b) => {
          const timeA = timeToSeconds(a.elapsedTime);
          const timeB = timeToSeconds(b.elapsedTime);

          if (timeA === timeB) {
            return a.name.localeCompare(b.name); // Alphabetical order for names
          }
          return timeA - timeB; // Sort by elapsedTime
        });

        setUsers(leaderboardData);
      } catch (error) {
        console.error("Error fetching leaderboard: ", error);
      }
    };

    fetchLeaderboard();
  }, []);

  return (
    <div className="leaderboard-container" style={{ flexDirection: "column" }}>
      <h2 style={{ textAlign: "center", color: "white" }}>LEADERBOARD</h2>
      <div className="leaderboard">
        {/* Vertical Lines Covering Full Height */}
        <div className="vertical-line left"></div>
        <div className="vertical-line right"></div>

        <div className="leaderboard-header">
          <span>Rank</span>
          <span>Name</span>
          <span>Time</span>
        </div>

        {users.map((user, index) => (
          <div key={user.id} className="leaderboard-row">
            <span>{index + 1}</span>
            <span>{user.name || "Player Name"}</span>
            <span>{user.elapsedTime || "00:00"}</span>
          </div>
        ))}
      </div>
      <button
        className="go-back-button"
        style={{
          marginTop: 20,
          backgroundColor: "rgba(202,46,50,229)",
          width: "80%",
          color: "white",
          borderRadius: 30,
        }}
        onClick={handleGoBack}
      >
        GO BACK
      </button>
    </div>
  );
};

export default Leaderboard;
