import React,{useContext, useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import bgImage from "../Assets/Page2/btn.png"; 
import { TimerContext } from '../TimerContext';
import {updateUserAttempts,getUserAttempt} from '../firebase'

import bg from "../Assets/Page2/bg.jpg"; 

const Dashboard = () => {
  const navigate = useNavigate();
  const { elapsedTime, startTimer } = useContext(TimerContext);
  const { timeLeft ,stopTimer} = useContext(TimerContext);
  const [buttonText, setButtonText] = useState('')
  const [userAttempt, setUserAttempt] = useState(0)

  const handleStopTimer = () => {
    localStorage.removeItem("timeLeft");
    stopTimer();
    
  };
  useEffect(() => {
    const getAttempts = async () => {
      const userEmail = localStorage.getItem("email");
      const attempts = await getUserAttempt(userEmail);
      setUserAttempt(attempts);
      if (attempts == 0) {
        setButtonText('START TREASURE HUNTING');
      } 
       else {
        console.log('attempts reached');
      }
    };
    getAttempts();
  }, []);

  const handleStartTimer = () => {
    startTimer();
    const userEmail = localStorage.getItem("email"); 
    updateUserAttempts(userEmail);
    localStorage.removeItem("randomTasks");
    localStorage.removeItem("taskStatuses");
  };
  const handleButtonClick = (route) => {
    navigate(route); 
  };

  const getFontSize = (text) => {
    if (text.length > 20) {
      return "12px"; 
    }
    return "18px"; 
  };
  const buttonStyle = {
    background: "rgba(196, 22, 28, 0.9)", 
    color: "white",
    border: "none",
    padding: "15px 30px",
    margin: "10px 0",
    fontSize: getFontSize(buttonText),
    fontWeight: "bold",
    width: "80%",
    maxWidth: "300px",
    borderRadius: "30px",
    letterSpacing: "2px", 
    cursor: "pointer",
    transition: "transform 0.3s ease, opacity 0.3s ease",
  };
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:'column',
        fontFamily:'GTRegular'
        
      }}
    >
    
{userAttempt<=0 ?(<>

  <button
        onClick={() => {handleButtonClick("/treasure")
          handleStartTimer();
        }}
        style={{
          ...buttonStyle,
          // whiteSpace: "nowrap", 
          // overflow: "hidden", 
          // textOverflow: "ellipsis", 
        }}
      >
     {buttonText}
      </button>
</>):(<></>)}
    

      <button
        onClick={() => handleButtonClick("/leaderboard")}
        style={buttonStyle}
      >
        LEADERBOARD
      </button>

      <button
        onClick={() => handleButtonClick("/profile")}
        style={buttonStyle}
      >
        MY PROFILE
      </button>

      {/* <button
        onClick={() => handleButtonClick("/rewards")}
        style={buttonStyle}
      >
        REWARDS
      </button> */}

      <button
        onClick={() => {
          localStorage.removeItem("userSession");
          localStorage.removeItem("email");
          localStorage.removeItem("randomTasks");
          localStorage.removeItem("taskStatuses");
          localStorage.removeItem("timeLeft"); 
          localStorage.removeItem('startTime');
          handleStopTimer();
          navigate("/login");
        }}
        style={buttonStyle}
      >
        SIGN OUT
      </button>
    </div>
  );
};


export default Dashboard;








// import React, { useContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { TimerContext } from "../TimerContext";
// import { updateUserAttempts, getUserAttempt } from "../firebase";
// import bg from "../Assets/Page2/bg.jpg";

// const Dashboard = () => {
//   const navigate = useNavigate();
//   const { elapsedTime, startTimer, stopTimer } = useContext(TimerContext);
//   const [buttonText, setButtonText] = useState("");
//   const [userAttempt, setUserAttempt] = useState(0);
//   const [tasksCompleted, setTasksCompleted] = useState(false);

//   const handleStopTimer = () => {
//     localStorage.removeItem("timeLeft");
//     stopTimer();
//   };

//   useEffect(() => {
//     const getAttempts = async () => {
//       const userEmail = localStorage.getItem("email");
//       const attempts = await getUserAttempt(userEmail);
//       setUserAttempt(attempts);

//       // Set buttonText based on attempts and task status
//       if (attempts === 0) {
//         setButtonText("START TREASURE HUNTING");
//       } else if (attempts === 1 && elapsedTime > 0 && !tasksCompleted) {
//         setButtonText("RESUME TREASURE HUNTING");
//       } else {
//         console.log('atempt finished')
//       }
//     };

//     const checkTasksStatus = () => {
//       const taskStatuses = JSON.parse(localStorage.getItem("taskStatuses")) || {};
//       const totalTasks = 6; // Update this if your total number of tasks changes
//       const allTasksCompleted = Array.from({ length: totalTasks }).every(
//         (_, index) => taskStatuses[`task_${index + 1}_Status`] === "Completed"
//       );

//       console.log("Task statuses:", taskStatuses);
//       console.log("All tasks completed:", allTasksCompleted);

//       setTasksCompleted(allTasksCompleted);
//     };

//     getAttempts();
//     checkTasksStatus();
//   }, [elapsedTime, tasksCompleted]); // Add dependencies to update when time or task status changes

//   const handleStartTimer = () => {
//     startTimer();
//     const userEmail = localStorage.getItem("email");
//     updateUserAttempts(userEmail);
//     localStorage.removeItem("randomTasks");
//     localStorage.removeItem("taskStatuses");
//   };

//   const handleButtonClick = (route) => {
//     navigate(route);
//   };

//   const getFontSize = (text) => {
//     if (text.length > 18) {
//       return "12px";
//     }
//     return "18px";
//   };

//   const buttonStyle = {
//     background: "rgba(196, 22, 28, 0.9)",
//     color: "white",
//     border: "none",
//     padding: "15px 30px",
//     margin: "10px 0",
//     fontSize: getFontSize(buttonText),
//     fontWeight: "bold",
//     width: "80%",
//     maxWidth: "300px",
//     borderRadius: "30px",
//     letterSpacing: "2px",
//     cursor: "pointer",
//     transition: "transform 0.3s ease, opacity 0.3s ease",
//   };

//   return (
//     <div
//       style={{
//         backgroundImage: `url(${bg})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         minHeight: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         flexDirection: "column",
//         fontFamily: "GTRegular",
//       }}
//     >
//       <button
//         onClick={() => {
//           if (userAttempt <= 0) handleStartTimer();
//           handleButtonClick("/treasure");
//         }}
//         style={buttonStyle}
//       >
//         {buttonText}
//       </button>

//       <button
//         onClick={() => handleButtonClick("/leaderboard")}
//         style={buttonStyle}
//       >
//         LEADERBOARD
//       </button>

//       <button
//         onClick={() => handleButtonClick("/profile")}
//         style={buttonStyle}
//       >
//         MY PROFILE
//       </button>

//       <button
//         onClick={() => {
//           localStorage.removeItem("userSession");
//           localStorage.removeItem("email");
//           localStorage.removeItem("randomTasks");
//           localStorage.removeItem("taskStatuses");
//           localStorage.removeItem("timeLeft");
//           handleStopTimer();
//           navigate("/login");
//         }}
//         style={buttonStyle}
//       >
//         SIGN OUT
//       </button>
//     </div>
//   );
// };

// export default Dashboard;
