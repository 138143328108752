// import React, { useState, useEffect } from "react";
// import {
//   getFirestore,
//   collection,
//   addDoc,
//   updateDoc,
//   deleteDoc,
//   doc,
//   onSnapshot,
// } from "firebase/firestore";
// import { useNavigate } from "react-router-dom";

// const db = getFirestore();

// const AdminPanel = () => {
//   const [tasks, setTasks] = useState([]);
//   const [clues, setClues] = useState([]);
//   const [modalType, setModalType] = useState("");
//   const [taskName, setTaskName] = useState("");
//   const [clue, setClue] = useState("");
//   const [newClue, setNewClue] = useState("");
//   const [editingTaskId, setEditingTaskId] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {

//     const unsubscribeTasks = onSnapshot(collection(db, "tasks"), (snapshot) => {
//       const fetchedTasks = snapshot.docs.map((doc, index) => ({
//         id: doc.id,
//         ...doc.data(),
//         index: index + 1,
//       }));
//       setTasks(fetchedTasks);
//     });

//     const unsubscribeClues = onSnapshot(collection(db, "clues"), (snapshot) => {
//       const fetchedClues = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setClues(fetchedClues);
//     });

//     return () => {
//       unsubscribeTasks();
//       unsubscribeClues();
//     };
//   }, []);

//   const openModal = (type, task = {}) => {
//     setModalType(type);
//     setTaskName(type === "updateTask" ? task.taskName : "");
//     setClue(type === "updateTask" ? task.clue : "");
//     setEditingTaskId(type === "updateTask" ? task.id : null);
//     setNewClue("");
//   };

//   const closeModal = () => {
//     setModalType("");
//     setTaskName("");
//     setClue("");
//     setEditingTaskId(null);
//     setNewClue("");
//   };
//   const handleGoBack = () => {
//     window.history.back();
//   };

//   const handleSaveTask = async () => {
//     if (taskName && clue) {
//       try {
//         if (modalType === "addTask") {

//           await addDoc(collection(db, "tasks"), { taskName, clue });
//         } else if (modalType === "updateTask" && editingTaskId) {
//           // Update existing task
//           const taskDocRef = doc(db, "tasks", editingTaskId);
//           await updateDoc(taskDocRef, { taskName, clue });
//         }
//         closeModal();
//       } catch (error) {
//         console.error("Error saving task: ", error);
//       }
//     } else {
//       alert("Please fill in all fields.");
//     }
//   };

//   const handleSaveClue = async () => {
//     if (newClue) {
//       try {
//         // Add new clue
//         await addDoc(collection(db, "clues"), { clue: newClue });
//         closeModal();
//       } catch (error) {
//         console.error("Error saving clue: ", error);
//       }
//     } else {
//       alert("Please provide a clue.");
//     }
//   };

//   const ViewUsers =()=>{
//     navigate('/allusers')
//   }
//   const handleDeleteTask = async (taskId) => {
//     const userConfirmed = window.confirm("Are you sure you want to delete this task?");

//     if (userConfirmed) {
//       try {
//         await deleteDoc(doc(db, "tasks", taskId));
//         console.log("Task deleted successfully.");
//       } catch (error) {
//         console.error("Error deleting task: ", error);
//       }
//     } else {
//       console.log("Task deletion canceled.");
//     }
//   };

//   return (
//     <div style={{ maxWidth: "80%", margin: "0 auto", padding: "20px" ,backgroundColor:'white',}}>
//       <h1 style={{ textAlign: "center", color: "#333" }}>Admin Panel</h1>

//       <div style={{ display: "flex", gap: "10px", marginBottom: "20px" ,  }}>
//         <button
//           onClick={() => openModal("addTask")}
//           style={{
//             flex: 1,
//             padding: "10px",
//             background: 'rgba(196, 22, 28, 0.9)',
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           Add New Task
//         </button>
//         <button
//           onClick={() => openModal("addClue")}
//           style={{
//             flex: 1,
//             padding: "10px",
//             background: 'rgba(196, 22, 28, 0.9)',
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           Add New Clue
//         </button>
//       </div>

// <div style={{display:'flex', height:'60%', overflowY:'auto',}}>
//       <table
//   style={{
//     width: "100%",
//     borderCollapse: "collapse",
//     marginBottom: "20px",
//     maxHeight: "500px",
//     overflowY: "auto",
//     display: "block",
//   }}
//       >
//         <thead>
//           <tr style={{ backgroundColor: "#f8f9fa", textAlign: "left" }}>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>Index</th>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>
//               Task Name
//             </th>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>Clue</th>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>
//               Actions
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {tasks.map((task) => (
//             <tr key={task.id}>
//               <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                 {task.index}
//               </td>
//               <td style={{ padding: "10px", border: "1px solid #ddd", textWrap:'wrap',width:'30%' }}>
//                 {task.taskName}
//               </td>
//               <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                 {task.clue}
//               </td>
//               <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                 <button
//                   onClick={() => openModal("updateTask", task)}
//                   style={{
//                     marginRight: "10px",
//                     padding: "5px 10px",
//                     backgroundColor: "#ffc107",
//                     color: "white",
//                     border: "none",
//                     borderRadius: "5px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Update
//                 </button>
//                 <button
//                   onClick={() => handleDeleteTask(task.id)}
//                   style={{
//                     padding: "5px 10px",
//                     backgroundColor: "#dc3545",
//                     color: "white",
//                     border: "none",
//                     borderRadius: "5px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Delete
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       </div>
//       {modalType && (
//         <div
//           style={{
//             position: "fixed",
//             top: "0",
//             left: "0",
//             width: "100%",
//             height: "100%",
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             zIndex: "1000",
//           }}
//         >
//           <div
//             style={{
//               backgroundColor: "white",
//               padding: "20px",
//               borderRadius: "8px",
//               width: "400px",
//               boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//             }}
//           >
//             <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
//               {modalType === "addTask"
//                 ? "Add New Task"
//                 : modalType === "updateTask"
//                 ? "Update Task"
//                 : "Add New Clue"}
//             </h2>

//             {modalType === "addTask" || modalType === "updateTask" ? (
//               <>
//                 <input
//                   type="text"
//                   placeholder="Task Name"
//                   value={taskName}
//                   onChange={(e) => setTaskName(e.target.value)}
//                   style={{
//                     width: "80%",
//                     padding: "10px",
//                     marginBottom: "10px",
//                     borderRadius: "5px",
//                     border: "1px solid #ccc",
//                   }}
//                 />
//                 <select
//                   value={clue}
//                   onChange={(e) => setClue(e.target.value)}
//                   style={{
//                     width: "90%",
//                     padding: "10px",
//                     marginBottom: "20px",
//                     borderRadius: "5px",
//                     border: "1px solid #ccc",
//                   }}
//                 >
//                   <option value="">Select a clue</option>
//                   {clues.map((clue) => (
//                     <option key={clue.id} value={clue.clue}>
//                       {clue.clue}
//                     </option>
//                   ))}
//                 </select>
//                 <button
//                   onClick={handleSaveTask}
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     backgroundColor: "#28a745",
//                     color: "white",
//                     border: "none",
//                     borderRadius: "5px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Save
//                 </button>
//               </>
//             ) : (
//               <>
//                 <input
//                   type="text"
//                   placeholder="Clue"
//                   value={newClue}
//                   onChange={(e) => setNewClue(e.target.value)}
//                   style={{
//                     width: "80%",
//                     padding: "10px",
//                     marginBottom: "20px",
//                     borderRadius: "5px",
//                     border: "1px solid #ccc",
//                   }}
//                 />
//                 <button
//                   onClick={handleSaveClue}
//                   style={{
//                     width: "90%",
//                     padding: "10px",
//                     backgroundColor: "#007bff",
//                     color: "white",
//                     border: "none",
//                     borderRadius: "5px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Save
//                 </button>
//               </>
//             )}

//             <button
//               onClick={closeModal}
//               style={{
//                 marginTop: "10px",
//                 width: "100%",
//                 padding: "10px",
//                 backgroundColor: "#6c757d",
//                 color: "white",
//                 border: "none",
//                 borderRadius: "5px",
//                 cursor: "pointer",
//               }}
//             >
//               Cancel
//             </button>
//           </div>
//         </div>
//       )}
//          {/* <button
//               onClick={ViewUsers}
//               style={{
//                 marginTop: "10px",
//                 width: "40%",
//                 padding: "10px",
//                 backgroundColor: "#6c757d",
//                 color: "white",
//                 border: "none",
//                 borderRadius: "5px",
//                 cursor: "pointer",
//                 fontWeight:'bold'
//               }}
//             >
//          View Registered Users
//             </button> */}
//               <button className="go-back-button" style={{ marginTop:0, backgroundColor:'red',color:'white'}} onClick={handleGoBack}>
//             GO BACK
//           </button>
//     </div>
//   );
// };

// export default AdminPanel;

import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";

const db = getFirestore();

const AdminPanel = () => {
  const { zoneNumber } = useParams();
  const navigate = useNavigate();

  const [tasks, setTasks] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [clue, setClue] = useState("");
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [clues, setClues] = useState([]);
  const [newClue, setNewClue] = useState("");
  const [modalType, setModalType] = useState("");

  // Fetch tasks for the current zone
  useEffect(() => {
    const tasksQuery = query(
      collection(db, "tasks"),
      where("zone", "==", parseInt(zoneNumber, 10))
    );

    const unsubscribe = onSnapshot(tasksQuery, (snapshot) => {
      const fetchedTasks = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTasks(fetchedTasks);
    });

    return () => unsubscribe();
  }, [zoneNumber]);

  // Fetch clues for the current zone
  useEffect(() => {
    const cluesQuery = query(
      collection(db, "clues"),
      where("zone", "==", parseInt(zoneNumber, 10))
    );

    const unsubscribe = onSnapshot(cluesQuery, (snapshot) => {
      const fetchedClues = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClues(fetchedClues);
    });

    return () => unsubscribe();
  }, [zoneNumber]);

  // Add or update a task
  const handleSaveTask = async () => {
    if (taskName && clue) {
      try {
        if (editingTaskId) {
          const taskDocRef = doc(db, "tasks", editingTaskId);
          await updateDoc(taskDocRef, { taskName, clue });
        } else {
          await addDoc(collection(db, "tasks"), {
            taskName,
            clue,
            zone: parseInt(zoneNumber, 10),
          });
        }
        closeModal();
      } catch (error) {
        console.error("Error saving task: ", error);
      }
    } else {
      alert("Please fill in all fields.");
    }
  };

  // Delete a task
  const handleDeleteTask = async (taskId) => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      try {
        await deleteDoc(doc(db, "tasks", taskId));
      } catch (error) {
        console.error("Error deleting task: ", error);
      }
    }
  };

  // Add a new clue
  const handleSaveClue = async () => {
    if (newClue) {
      try {
        await addDoc(collection(db, "clues"), {
          clue: newClue,
          zone: parseInt(zoneNumber, 10),
        });
        closeModal();
      } catch (error) {
        console.error("Error saving clue: ", error);
      }
    } else {
      alert("Please provide a clue.");
    }
  };

  const openModal = (type, task = {}) => {
    setModalType(type);
    setTaskName(type === "updateTask" ? task.taskName : "");
    setClue(type === "updateTask" ? task.clue : "");
    setEditingTaskId(type === "updateTask" ? task.id : null);
  };

  const closeModal = () => {
    setModalType("");
    setTaskName("");
    setClue("");
    setEditingTaskId(null);
    setNewClue("");
  };

  const handleGoBack = () => navigate(-1);

  return (
    <div style={{ maxWidth: "80%", margin: "0 auto", padding: "20px", backgroundColor: "white" }}>
      <h1 style={{ textAlign: "center", color: "#333" }}>Zone {zoneNumber}</h1>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <button onClick={() => openModal("addTask")} style={buttonStyle}>
          Add New Task
        </button>
        <button onClick={() => openModal("addClue")} style={buttonStyle}>
          Add New Clue
        </button>
      </div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={cellStyle}>Index</th>
            <th style={cellStyle}>Task Name</th>
            <th style={cellStyle}>Clue</th>
            <th style={cellStyle}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr key={task.id}>
              <td style={cellStyle}>{index + 1}</td>
              <td style={cellStyle}>{task.taskName}</td>
              <td style={cellStyle}>{task.clue}</td>
              <td style={cellStyle}>
                <button onClick={() => openModal("updateTask", task)} style={actionButtonStyle}>
                  Update
                </button>
                <button onClick={() => handleDeleteTask(task.id)} style={actionButtonStyle}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modalType && (
        <div style={modalOverlayStyle}>
          <div style={modalStyle}>
            <h2>{modalType === "addTask" ? "Add Task" : modalType === "updateTask" ? "Update Task" : "Add Clue"}</h2>
            {modalType !== "addClue" ? (
              <>
                <input
                  type="text"
                  placeholder="Task Name"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  style={inputStyle}
                />
           <select value={clue} onChange={(e) => setClue(e.target.value)} style={inputStyle}>
  <option value="">Select Clue</option>
  {[...new Set(clues.map((cl) => cl.clue))].map((uniqueClue, index) => (
    <option key={index} value={uniqueClue}>
      {uniqueClue}
    </option>
  ))}
</select>

                <button onClick={handleSaveTask} style={saveButtonStyle}>
                  Save
                </button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Clue"
                  value={newClue}
                  onChange={(e) => setNewClue(e.target.value)}
                  style={inputStyle}
                />
                <button onClick={handleSaveClue} style={saveButtonStyle}>
                  Save
                </button>
              </>
            )}
            <button onClick={closeModal} style={cancelButtonStyle}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const buttonStyle = {
  padding: "10px",
  backgroundColor: "#c4161c",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "20px",
};

const cellStyle = {
  padding: "10px",
  border: "1px solid #ddd",
};

const actionButtonStyle = {
  marginRight: "5px",
  padding: "5px 10px",
  backgroundColor: "#ffc107",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

const modalOverlayStyle = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  width: "400px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
};

const inputStyle = {
  width: "90%",
  padding: "10px",
  marginBottom: "10px",
  borderRadius: "5px",
  border: "1px solid #ccc",
};

const saveButtonStyle = {
  padding: "10px 20px",
  backgroundColor: "#28a745",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  marginRight: "10px",
};

const cancelButtonStyle = {
  padding: "10px 20px",
  backgroundColor: "#6c757d",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default AdminPanel;

