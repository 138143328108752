// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
// import bgImage from "../../Assets/Page2/bg.png";
// import formBgImage from "../../Assets/Page2/s.png"; // Form background image

// const db = getFirestore();

// const AdminLoginPanel= () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();

//   // Check if user session exists on component mount
//   useEffect(() => {
//     const session = localStorage.getItem("userSession");
//     // if (session) {
//     //   const sessionData = JSON.parse(session);
//     //   const now = new Date().getTime();
//     //   if (now - sessionData.timestamp < 2 * 60 * 60 * 1000) {
//     //     // Check if terms are accepted
//     //     if (sessionData.termsAccepted) {
//     //       navigate("/dashboard"); // Redirect to dashboard if terms are accepted
//     //     } else {
//     //       navigate("/terms"); // Redirect to terms if terms are not accepted
//     //     }
//     //   } else {
//     //     localStorage.removeItem("userSession"); // Expire session
//     //   }
//     // }
//   }, [navigate]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setErrorMessage("");

//     try {
//         // Query Firestore to find the user by email
//         const userCollection = collection(db, "users");
//         const q = query(userCollection, where("email", "==", email));
//         const querySnapshot = await getDocs(q);
      
//         if (querySnapshot.empty) {
//           setErrorMessage("User not found");
//           return;
//         }
      
//         const userData = querySnapshot.docs[0].data();
      
//         // Match password
//         if (userData.password !== password) {
//           setErrorMessage("Invalid email or password");
//           return;
//         }
      
//         // Check if the user is an admin
//         if (userData.isAdmin !== true) {
//           setErrorMessage("You are not authorized to access the admin panel");
//           return;
//         }
      
//         // Save session to localStorage
//         console.log("Login successful!");
//         localStorage.setItem('email', email);
        
//         // Redirect to the admin panel or any other page after successful login
//         navigate("/admindashboard");  // Redirect to admin panel
      
//       } catch (error) {
//         console.error("Error during login: ", error);
//         setErrorMessage("Something went wrong. Please try again.");
//       }
      
//     }
//   return (
//     <div
//       className="login-container"
//       style={{
//         backgroundImage: `url(${bgImage})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         minHeight: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         // backgroundColor:'white'
//       }}
//     >
//       <form
//         className="login-form"
//         style={{
//           backgroundImage: `url(${formBgImage})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           padding: "20px",
//           borderRadius: "10px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           width: "80%",
//           maxWidth: "400px",
//         }}
//         onSubmit={handleSubmit}
//       >
//         <h2 style={{ color: "white", textAlign: "center", marginBottom: "20px" }}>
//           Login
//         </h2>
//         {errorMessage && (
//           <p style={{ color: "black", textAlign: "center" }}>{errorMessage}</p>
//         )}
  
     
//      <div style={inputContainerStyle}>
//           <label style={labelStyle}>Email</label>
//           <input
//            type="email"
//            name="email"
//           //  placeholder="Email"
//            style={inputStyle}
//            value={email}
//            onChange={(e) => setEmail(e.target.value)}
//            required
//           />
//         </div>

// <div style={inputContainerStyle}>
//           <label style={labelStyle}>Password</label>
//           <input
//             type="password"
//             name="password"
//             // placeholder="Password"
//             style={inputStyle}
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>
//         <button
//           type="submit"
//           style={{
//             background: "white",
//             color: "red",
//             border: "none",
//             borderRadius: "15px",
//             padding: "10px 15px",
//             cursor: "pointer",
//             fontWeight: "bold",
//             width: "90%",
//             marginTop:5
//           }}
//         >
//           Login
//         </button>
//         <div style={{ color: "white", width:'80%',textAlignLast:'left' ,placeSelf:'center'}}>
//         <p style={{ color: "white",fontWeight:'bold' }}>
//           Forgot{" "}
//           <span
//             style={{ color: "white", cursor: "pointer" }}
//             onClick={() => navigate("/forgotpassword")}
//           >
//             Password?
//           </span>
//         </p>
//         </div>
//       </form>
//     </div>
//   );
// };

// const inputContainerStyle = {
//   display: "flex",
//   flexDirection: "column",
//   marginBottom: "10px",
//   justifySelf:'center',
//   width:'90%'
// };

// const labelStyle = {
//   color: "white",
//   marginBottom: "5px",
//   fontSize: "14px",
//   textAlignLast: 'left'
// };

// const inputStyle = {
//   width: "100%",
//   // padding: "10px",
//   border: "2px solid white",
//   borderRadius: "15px",
//   backgroundColor: "transparent",
//   color: "white",
//   outline: "none",
//   padding: "10px 0px"
// };
// export default AdminLoginPanel;
















import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import bgImage from "../../Assets/Page2/bg.jpg";
import formBgImage from "../../Assets/Page2/s.png"; // Form background image

const db = getFirestore();

const AdminLoginPanel = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Check if user session exists on component mount
  useEffect(() => {
    const session = localStorage.getItem("userSession");
    if (session) {
      const sessionData = JSON.parse(session);
      const now = new Date().getTime();
      // Check session expiration (2 hours in this example)
      if (now - sessionData.timestamp < 2 * 60 * 60 * 1000) {
        // Check if terms are accepted
        if (sessionData.isAdmin) {
          navigate("/admindashboard"); // Redirect to the admin dashboard if valid session
        } else {
          setErrorMessage("Unauthorized access. Admin access only.");
          localStorage.removeItem("userSession");
        }
      } else {
        localStorage.removeItem("userSession"); // Expire session if time exceeds limit
      }
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      // Query Firestore to find the user by email
      const userCollection = collection(db, "users");
      const q = query(userCollection, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setErrorMessage("User not found");
        return;
      }

      const userData = querySnapshot.docs[0].data();

      // Match password
      if (userData.password !== password) {
        setErrorMessage("Invalid email or password");
        return;
      }

      // Check if the user is an admin
      if (userData.isAdmin !== true) {
        setErrorMessage("You are not authorized to access the admin panel");
        return;
      }

      // Save session to localStorage
      const sessionData = {
        email,
        isAdmin: userData.isAdmin,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem("adminUserSession", JSON.stringify(sessionData));
      console.log("Login successful!");

      // Redirect to the admin panel
      navigate("/admindashboard");
    } catch (error) {
      console.error("Error during login: ", error);
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form
        className="login-form"
        style={{
          // backgroundImage: `url(${formBgImage})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          backgroundColor: "rgba(202,46,50,229)",
          padding: "20px",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "80%",
          maxWidth: "400px",
        }}
        onSubmit={handleSubmit}
      >
        <h2 style={{ color: "white", textAlign: "center", marginBottom: "20px" }}>
          Admin Login
        </h2>
        {errorMessage && (
          <p style={{ color: "black", textAlign: "center" }}>{errorMessage}</p>
        )}

        <div style={inputContainerStyle}>
          <label style={labelStyle}>Email</label>
          <input
            type="email"
            name="email"
            style={inputStyle}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle}>Password</label>
          <input
            type="password"
            name="password"
            style={inputStyle}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button
          type="submit"
          style={{
            background: "white",
            color: "red",
            border: "none",
            borderRadius: "15px",
            padding: "10px 15px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "90%",
            marginTop: 5,
          }}
        >
          Login
        </button>
        <div
          style={{
            color: "white",
            width: "80%",
            textAlignLast: "left",
            placeSelf: "center",
          }}
        >
          <p style={{ color: "white", fontWeight: "bold" }}>
            Forgot{" "}
            <span
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => navigate("/forgotpassword")}
            >
              Password?
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};

const inputContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  justifySelf: "center",
  width: "90%",
};

const labelStyle = {
  color: "white",
  marginBottom: "5px",
  fontSize: "14px",
  textAlignLast: "left",
};

const inputStyle = {
  width: "100%",
  border: "2px solid white",
  borderRadius: "15px",
  backgroundColor: "transparent",
  color: "white",
  outline: "none",
  padding: "10px 5px",
};

export default AdminLoginPanel;
