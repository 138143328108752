import React from "react";
import { useNavigate } from "react-router-dom";

const ZonePanel = () => {
  const navigate = useNavigate();

  const handleZoneClick = (zoneNumber) => {
    navigate(`/zone/${zoneNumber}`);
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" ,display:'flex',alignContent:'center',flexDirection:'column',alignItems:'center',color:'white'}}>
      {/* <h1></h1> */}
      {/* <p>Click on a zone to manage its tasks and clues</p> */}
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" ,flexDirection:'column',width:'20%',marginTop:80}}>
        {[1, 2, 3, 4, 5, 6].map((zone) => (
          <button
            key={zone}
            style={buttonStyle}
            onClick={() => handleZoneClick(zone)}
          >
            Zone {zone}
          </button>
        ))}
      </div>
    </div>
  );
};
const buttonStyle = {
    background: "white", 
    color: "rgba(196, 22, 28, 0.9)",
    border: "none",
    padding: "15px 30px",
    margin: "10px 0",
    fontSize: "18px",
    fontWeight: "bold",
    width: "80%",
    maxWidth: "300px",
    borderRadius: "10px",
    cursor: "pointer",
    transition: "transform 0.3s ease, opacity 0.3s ease",
  };
export default ZonePanel;
