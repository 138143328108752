// import logo from './logo.svg';
// import './App.css';
// import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';
// import Start from './Components/Start';
// import Signup from './Components/Signup'
// import Login from './Components/Login';
// import ForgotPassword from './Components/ForgotPassword';
// import OtpVerification from './Components/OTPVarification';
// import ResetPassword from './Components/PasswordReset';
// import TermsAndConditions from './Components/TermsAndCond';
// import Dashboard from './Components/Dashboard';
// import TreasureHunting from './Components/TreasureHunting';
// import AdminPanel from './Components/Admin/AdminPanel';
// import TaskDetail from './Components/TaskDetail';
// import { TimerProvider } from './TimerContext';
// import ThankYou from './Components/TimerFinished';
// import QRScannerPage from './Components/QRScanner';
// import { Toaster } from 'react-hot-toast';
// import UserListPage from './Components/Admin/AllusersList';
// import Leaderboard from './Components/Leaderboard';
// import Profile from './Components/ProfilePage';
// import AdminLoginPanel from './Components/Admin/AdminMainPanel';
// import AdminDashboard from './Components/Admin/AdminDashboard';
// import AdminRewards from './Components/Admin/AdminRewards'
// import Rewards from './Components/Rewards';
// import AdminSettings from './Components/Admin/AdminSettings';

// function App() {
//   return (
//     <div className="App">

//       <Router>

//       <TimerProvider>
//       <Toaster position="top-right" reverseOrder={false} />
//       <Routes>
     
//         <Route path='/' element={<Start></Start>}/>
//         <Route path='/signup' element={<Signup></Signup>}/>
//         <Route path='/login' element={<Login></Login>}/>
//         <Route path='/forgotpassword' element={<ForgotPassword></ForgotPassword>}/>
//         <Route path='/otp' element={<OtpVerification></OtpVerification>}/>
//         <Route path='/reset' element={<ResetPassword></ResetPassword>}/>
//         <Route path='/terms' element={<TermsAndConditions></TermsAndConditions>}></Route>
//         <Route path='/dashboard' element={<Dashboard></Dashboard>}></Route>
//         <Route path='/treasure' element={<TreasureHunting></TreasureHunting>}></Route>
//         <Route path='/admin' element={<AdminPanel></AdminPanel>}></Route>
//         <Route path="/task/:id" element={<TaskDetail />} />
//         <Route path="/thankyou" element={<ThankYou />} />
//         <Route path="/qr-scanner" element={<QRScannerPage />} />
//         <Route path="/allusers" element={<UserListPage />} />
//         <Route path="/leaderboard" element={<Leaderboard />} />
//         <Route path="/profile" element={<Profile />} />
//         <Route path="/adminlogin" element={<AdminLoginPanel />} />
//         <Route path="/admindashboard" element={<AdminDashboard />} />
//         <Route path="/adminrewards" element={<AdminRewards />} />
//         <Route path="/rewards" element={<Rewards />} />
//         <Route path="/settings" element={<AdminSettings />} />
//         <Route path="*" element={<Navigate to="/" replace />} />


//       </Routes>
      
//       </TimerProvider>
//       </Router>
    

//     </div>
//   );
// }

// export default App;























import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Start from './Components/Start';
import Signup from './Components/Signup';
import Login from './Components/Login';
import ForgotPassword from './Components/ForgotPassword';
import OtpVerification from './Components/OTPVarification';
import ResetPassword from './Components/PasswordReset';
import TermsAndConditions from './Components/TermsAndCond';
import Dashboard from './Components/Dashboard';
import TreasureHunting from './Components/TreasureHunting';
import AdminPanel from './Components/Admin/AdminPanel';
import TaskDetail from './Components/TaskDetail';
import { TimerProvider } from './TimerContext';
import ThankYou from './Components/TimerFinished';
import QRScannerPage from './Components/QRScanner';
import { Toaster } from 'react-hot-toast';
import UserListPage from './Components/Admin/AllusersList';
import Leaderboard from './Components/Leaderboard';
import Profile from './Components/ProfilePage';
import AdminLoginPanel from './Components/Admin/AdminMainPanel';
import AdminDashboard from './Components/Admin/AdminDashboard';
import AdminRewards from './Components/Admin/AdminRewards';
import Rewards from './Components/Rewards';
import AdminSettings from './Components/Admin/AdminSettings';
import ZonePanel from './Components/Admin/ZonePanel';

// Helper function for redirect
const ProtectedRoute = ({ children }) => {
  // Check for user session in localStorage
  const session = localStorage.getItem("userSession");

  if (session) {
    const sessionData = JSON.parse(session);
    const now = new Date().getTime();

    // Validate if the session is still valid (e.g., within 2 hours)
    if (now - sessionData.timestamp < 1 * 60 * 60 * 1000) {
      return children;
    } else {
    
      localStorage.removeItem("userSession");
    }
  }

  
  return <Navigate to="/" replace />;
};

const ProtectedRouteAdmin = ({ children }) => {
  // Check for user session in localStorage
  const session = localStorage.getItem("adminUserSession");

  if (session) {
    const sessionData = JSON.parse(session);
    const now = new Date().getTime();

    // Validate if the session is still valid (e.g., within 2 hours)
    if (now - sessionData.timestamp < 1 * 60 * 60 * 1000) {
      return children;
    } else {
    
      localStorage.removeItem("userSession");
    }
  }

  
  return <Navigate to="/adminlogin" replace />;
};

function App() {
  return (
    <div className="App">
      <Router>
        <TimerProvider>
          <Toaster position="top-right" reverseOrder={false} />
          <Routes>
    
            <Route path="/" element={<Start />} />

           
            <Route
              path="/signup"
              element={
                
                  <Signup />
                
              }
            />
            <Route
              path="/login"
              element={
               
                  <Login />
                
              }
            />
            <Route
              path="/forgotpassword"
              element={
               
                  <ForgotPassword />
               
              }
            />
      
            <Route
              path="/reset"
              element={
                <ProtectedRoute>
                  <ResetPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/terms"
              element={
                <ProtectedRoute>
                  <TermsAndConditions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/treasure"
              element={
                <ProtectedRoute>
                  <TreasureHunting />
                </ProtectedRoute>
              }
            />
            <Route
              // path="/admin"
               path="/zone/:zoneNumber"
              element={
                <ProtectedRouteAdmin>
                  <AdminPanel />
                </ProtectedRouteAdmin>
              }
            />
                 <Route
              // path="/admin"
               path="/zones"
              element={
                <ProtectedRouteAdmin>
                  <ZonePanel />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/task/:id"
              element={
                <ProtectedRoute>
                  <TaskDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/thankyou"
              element={
                <ProtectedRoute>
                  <ThankYou />
                </ProtectedRoute>
              }
            />
            <Route
              path="/qr-scanner"
              element={
                <ProtectedRoute>
                  <QRScannerPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/allusers"
              element={
                <ProtectedRouteAdmin>
                  <UserListPage />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <ProtectedRoute>
                  <Leaderboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/adminlogin"
              element={
               
                  <AdminLoginPanel />
               
              }
            />
            <Route
              path="/admindashboard"
              element={
                <ProtectedRouteAdmin>
                  <AdminDashboard />
                </ProtectedRouteAdmin>
              }
            />
            {/* <Route
              path="/adminrewards"
              element={
                <ProtectedRouteAdmin>
                  <AdminRewards />
                </ProtectedRouteAdmin>
              }
            /> */}
            {/* <Route
              path="/rewards"
              element={
                <ProtectedRoute>
                  <Rewards />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/settings"
              element={
                <ProtectedRouteAdmin>
                  <AdminSettings />
                </ProtectedRouteAdmin>
              }
            />
            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </TimerProvider>
      </Router>
    </div>
  );
}

export default App;

















