import React,{useContext, useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import bgImage from "../../Assets/Page2/btn.png"; 
import { TimerContext } from '../../TimerContext';
import {updateUserAttempts,getUserAttempt} from '../../firebase'

import bg from "../../Assets/Page2/bg.jpg"; 

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { startTimer, timeLeft } = useContext(TimerContext);
  const [buttonText, setButtonText] = useState('')
  const [userAttempt, setUserAttempt] = useState(0)


 


  const handleStartTimer = () => {
    startTimer();
    const userEmail = localStorage.getItem("email"); 
    updateUserAttempts(userEmail);
  };
  const handleButtonClick = (route) => {
    navigate(route); 
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:'column'
        
      }}
    >


      <button
        onClick={() => handleButtonClick("/zones")}
        style={buttonStyle}
      >
       Clue/TaskManagement
      </button>

      {/* <button
        onClick={() => handleButtonClick("/adminrewards")}
        style={buttonStyle}
      >
       RewardsManagement
      </button> */}

      <button
        onClick={() => handleButtonClick("/allusers")}
        style={buttonStyle}
      >
       Registration List
      </button>
      <button
        onClick={() => handleButtonClick("/settings")}
        style={buttonStyle}
      >
       Setting
      </button>


    </div>
  );
};

const buttonStyle = {
    background: "rgba(196, 22, 28, 0.9)", 
    color: "white",
    border: "none",
    padding: "15px 30px",
    margin: "10px 0",
    fontSize: "18px",
    fontWeight: "bold",
    width: "80%",
    maxWidth: "300px",
    borderRadius: "10px",
    cursor: "pointer",
    transition: "transform 0.3s ease, opacity 0.3s ease",
  };
export default AdminDashboard;
