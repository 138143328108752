import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import bgImage from "../../Assets/Page2/bg.jpg";
import formBgImage from "../../Assets/Page2/s.png";

const db = getFirestore();

const AdminSettings = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleGoBack = () => {
    window.history.back();
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();
    setMessage("");
    
    // Validation
    if (!currentPassword || !newPassword || !confirmPassword) {
      setMessage("All fields are required.");
      return;
    }
    if (newPassword.length < 6) {
      setMessage("New password must be at least 6 characters long.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage("New password and confirm password do not match.");
      return;
    }

    try {
      // Get the admin email from localStorage
      const email = localStorage.getItem("email");
      if (!email) {
        setMessage("Session expired. Please log in again.");
        navigate("/adminlogin"); // Redirect to login
        return;
      }

      // Query Firestore to find the admin user
      const userCollection = collection(db, "users");
      const q = query(userCollection, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setMessage("Admin user not found.");
        return;
      }

      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();

      // Verify current password
      if (userData.password !== currentPassword) {
        setMessage("Current password is incorrect.");
        return;
      }

      // Update the password
      const userDocRef = userDoc.ref;
      await updateDoc(userDocRef, { password: newPassword });
      setMessage("Password changed successfully!");
      setTimeout(() => {
        navigate("/admindashboard"); // Redirect to admin dashboard after success
      }, 1500);
    } catch (error) {
      console.error("Error updating password:", error);
      setMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <div
      className="settings-container"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:'column'
      }}
    >
      <form
        className="settings-form"
        style={{
          backgroundColor: "rgba(202,46,50,229)",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "80%",
          maxWidth: "400px",
        }}
        onSubmit={handleChangePassword}
      >
        <h2 style={{ color: "white", textAlign: "center", marginBottom: "20px" }}>
          Change Password
        </h2>
        {message && (
          <p style={{ color: "black", textAlign: "center" }}>{message}</p>
        )}

        <div style={inputContainerStyle}>
          <label style={labelStyle}>Current Password</label>
          <input
            type="password"
            placeholder="Current Password"
            style={inputStyle}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle}>New Password</label>
          <input
            type="password"
            placeholder="New Password"
            style={inputStyle}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle}>Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm Password"
            style={inputStyle}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>

        <button
          type="submit"
          style={{
            background: "white",
            color: "red",
            border: "none",
            borderRadius: "15px",
            padding: "10px 15px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "90%",
            marginTop: 5,
          }}
        >
          Update Password
        </button>
      </form>
      <button className="go-back-button" style={{ marginTop:20, backgroundColor:'red',color:'white'}} onClick={handleGoBack}>
            GO BACK
          </button>
    </div>
  );
};

const inputContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  justifySelf: "center",
  width: "90%",
};

const labelStyle = {
  color: "white",
  marginBottom: "5px",
  fontSize: "14px",
  textAlignLast: "left",
};

const inputStyle = {
  width: "100%",
  border: "2px solid white",
  borderRadius: "15px",
  backgroundColor: "transparent",
  color: "white",
  outline: "none",
  padding: "10px 5px",
};

export default AdminSettings;
