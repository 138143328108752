// import React, { useState, useContext } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import { Scanner } from "@yudiel/react-qr-scanner";
// import { TimerContext, } from "../TimerContext";
// import { updateUserScore, getUserAttempt } from "../firebase"; 
// import toast from "react-hot-toast";
// import bgImage from "../Assets/Page2/bg.png";
// import formBgImage from "../Assets/Page2/s.png"; // 

// const QRScannerPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { taskIndex, correctQR } = location.state;
//   const { timeLeft,stopTimer } = useContext(TimerContext);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [scores,setScores] = useState('')

//   const handleStopTimer = () => {
//     localStorage.removeItem("timeLeft");
//     stopTimer();
    
//   };
//   const GoBackButton = () => {
//     const handleGoBack = () => {
//       window.history.back(); // Go back to the previous page in history
//     };
//   }
  
 



//   const handleScan = async (results) => {
//     if (results && results.length > 0) {
//       const rawValue = results[0].rawValue;
  
//       console.log(`Scanned QR: ${rawValue}, Expected QR: ${correctQR}`);
//       if (rawValue === correctQR) {
//         const taskStatuses = JSON.parse(localStorage.getItem("taskStatuses")) || {};
  
//         taskStatuses[`task_${taskIndex + 1}_Status`] = "Completed";
//         localStorage.setItem("taskStatuses", JSON.stringify(taskStatuses));
//         console.log(`Task ${taskIndex + 1} marked as completed.`);
  
//         const allTasksCompleted = Array.from({ length: 6 }).every(
//           (_, index) => taskStatuses[`task_${index + 1}_Status`] === "Completed"
//         );
  
//         if (allTasksCompleted) {
//           if (timeLeft === 0) {
//             // Prevent score from being added if time is up
//             alert("Sorry! Time is up! No scores can be given.");
//             navigate("/dashboard"); // Redirect to dashboard or another appropriate page
//             return;
//           }
  
//           const userEmail = localStorage.getItem("email");
//           try {
//             const attempt = await getUserAttempt(userEmail);
//             if (attempt <= 1) {
//               let scoreToAdd = 0;
//               if (timeLeft >= 5) scoreToAdd = 100;
//               else if (timeLeft >= 3) scoreToAdd = 80;
//               else if (timeLeft > 0) scoreToAdd = 60;
  
//               setScores(scoreToAdd);
//               if (scoreToAdd > 0) {
//                 await updateUserScore(userEmail, scoreToAdd);
//                 toast.success(
//                   `All tasks completed! Attempt ${attempt + 1} recorded. Score updated: +${scoreToAdd}`
//                 );
//                 handleStopTimer();
//                 navigate("/thankyou", {
//                   state: {
//                     scores: scoreToAdd,
//                   },
//                 });
//               } else {
//                 toast.info("Timer is zero, no score added.");
//               }
//             } else {
//               toast.info("Maximum attempts reached. No score updated.");
//             }
//           } catch (error) {
//             toast.error("Error updating score or fetching attempts. Please try again.");
//             console.error(error);
//           }
//         } else {
//           toast.success(`Task ${taskIndex + 1} marked as completed.`);
//           navigate("/treasure");
//         }
//       } else {
//         toast.error("QR code does not match. Please try again.");
//       }
//     }
//   };
  


  
//   const handleError = (error) => {
//     console.error("QR Scanner Error:", error);
//     setErrorMessage("Error scanning QR code. Please try again.");
//   };

//   return (
//     <div       style={{
//       backgroundImage: `url(${bgImage})`,
//       backgroundSize: "cover",
//       backgroundPosition: "center",
//       minHeight: "100vh",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       flexDirection:'column',
//       fontFamily:'GTRegular'
//     }}>
//          <div style={{width:'70%',marginTop:-60}}>
//       <h1 style={{color:'white',marginTop:-70}}>Scan QR Code</h1>
   
//       <Scanner
//         onScan={(result) => handleScan(result)}
//         onError={(error) => handleError(error)}
//         style={{ width: "80%", height: "300px" }}
//       />
// </div>
//       {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
//       {/* <button onClick={() => navigate(-1)}>Go Back</button> */}
//     </div>
//   );
// };

// export default QRScannerPage;





















// import React, { useState, useContext } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import { Scanner } from "@yudiel/react-qr-scanner";
// import { TimerContext } from "../TimerContext";
// import { updateElapsedTime } from "../firebase"; // Function to save elapsed time
// import toast from "react-hot-toast";
// import bgImage from "../Assets/Page2/bg.png";

// const QRScannerPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { taskIndex, correctQR } = location.state;
//   const { elapsedTime, stopTimer } = useContext(TimerContext);
//   const [errorMessage, setErrorMessage] = useState("");

//   const handleStopTimer = () => {
//     localStorage.removeItem("timeLeft");
//     stopTimer();
//   };

//   const handleScan = async (results) => {
//     if (results && results.length > 0) {
//       const rawValue = results[0].rawValue;

//       console.log(`Scanned QR: ${rawValue}, Expected QR: ${correctQR}`);
//       if (rawValue === correctQR) {
//         const taskStatuses = JSON.parse(localStorage.getItem("taskStatuses")) || {};

//         taskStatuses[`task_${taskIndex + 1}_Status`] = "Completed";
//         localStorage.setItem("taskStatuses", JSON.stringify(taskStatuses));
//         console.log(`Task ${taskIndex + 1} marked as completed.`);

//         const allTasksCompleted = Array.from({ length: 6 }).every(
//           (_, index) => taskStatuses[`task_${index + 1}_Status`] === "Completed"
//         );

//         if (allTasksCompleted) {
//           const userEmail = localStorage.getItem("email");
//           try {
//             // Save elapsed time to Firestore
//             await updateElapsedTime(userEmail, elapsedTime);
//             toast.success("You have completed all tasks successfully.");
//             handleStopTimer();

//             navigate("/thankyou", {
//               state: {
//                 elapsedTime,
//               },
//             });
//           } catch (error) {
//             toast.error("Error saving elapsed time. Please try again.");
//             console.error(error);
//           }
//         } else {
//           toast.success(`Task ${taskIndex + 1} marked as completed.`);
//           navigate("/treasure");
//         }
//       } else {
//         toast.error("QR code does not match. Please try again.");
//       }
//     }
//   };

//   const handleError = (error) => {
//     console.error("QR Scanner Error:", error);
//     setErrorMessage("Error scanning QR code. Please try again.");
//   };

//   return (
//     <div
//       style={{
//         backgroundImage: `url(${bgImage})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         minHeight: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         flexDirection: "column",
//         fontFamily: "GTRegular",
//       }}
//     >
//       <div style={{ width: "70%", marginTop: -60 }}>
//         <h1 style={{ color: "white", marginTop: -70 }}>Scan QR Code</h1>

//         <Scanner
//           onScan={(result) => handleScan(result)}
//           onError={(error) => handleError(error)}
//           style={{ width: "80%", height: "300px" }}
//         />
//       </div>
//       {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
//     </div>
//   );
// };

// export default QRScannerPage;















import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Scanner } from "@yudiel/react-qr-scanner";
import { TimerContext } from "../TimerContext";
import { updateElapsedTime } from "../firebase"; // Function to save elapsed time
import toast from "react-hot-toast";
import bgImage from "../Assets/Page2/bg.jpg";

// Function to format elapsed time as MM:SS
const formatTime = (time) => {
  const hours = Math.floor(time / 3600).toString().padStart(2, "0");
  const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, "0");
  const seconds = (time % 60).toString().padStart(2, "0");

  return hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};

const QRScannerPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { taskIndex, correctQR } = location.state;
  const { elapsedTime, stopTimer } = useContext(TimerContext);
  const [errorMessage, setErrorMessage] = useState("");

  const handleStopTimer = () => {
    localStorage.removeItem("timeLeft");
    stopTimer();
  };

  const handleScan = async (results) => {
    if (results && results.length > 0) {
      const rawValue = results[0].rawValue;

      console.log(`Scanned QR: ${rawValue}, Expected QR: ${correctQR}`);
      if (rawValue === correctQR) {
        const taskStatuses = JSON.parse(localStorage.getItem("taskStatuses")) || {};

        taskStatuses[`task_${taskIndex + 1}_Status`] = "Completed";
        localStorage.setItem("taskStatuses", JSON.stringify(taskStatuses));
        console.log(`Task ${taskIndex + 1} marked as completed.`);

        const allTasksCompleted = Array.from({ length: 6 }).every(
          (_, index) => taskStatuses[`task_${index + 1}_Status`] === "Completed"
        );

        if (allTasksCompleted) {
          const userEmail = localStorage.getItem("email");
          const formattedTime = formatTime(elapsedTime); // Format elapsed time

          try {
            // Save formatted time to Firestore
            await updateElapsedTime(userEmail, formattedTime);
            toast.success("All tasks completed! Time recorded successfully.");
            handleStopTimer();

            navigate("/thankyou", {
              state: {
                elapsedTime: formattedTime, // Send formatted time to thank you page
              },
            });
          } catch (error) {
            toast.error("Error saving elapsed time. Please try again.");
            console.error(error);
          }
        } else {
          toast.success(`Task ${taskIndex + 1} marked as completed.`);
          navigate("/treasure");
        }
      } else {
        toast.error("QR code does not match. Please try again.");
      }
    }
  };

  const handleError = (error) => {
    console.error("QR Scanner Error:", error);
    setErrorMessage("Error scanning QR code. Please try again.");
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontFamily: "GTRegular",
      }}
    >
      <div style={{ width: "70%", marginTop: -60 }}>
        <h1 style={{ color: "white", marginTop: -70 }}>Scan QR Code</h1>

        <Scanner
          onScan={(result) => handleScan(result)}
          onError={(error) => handleError(error)}
          style={{ width: "80%", height: "300px" }}
        />
      </div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default QRScannerPage;
