import React, { useState, useEffect } from "react";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import bg from "../Assets/Page2/bg.jpg";
import formBgImage from "../Assets/Page2/s.png"; // Form background image
const db = getFirestore(); // Import your Firebase config and Firestore initialization

export default function Rewards() {
  const [userPoints, setUserPoints] = useState(null);
  const [reward, setReward] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const handleGoBack = () => {
    window.history.back(); // Go back to the previous page in history
  };

  useEffect(() => {
    const fetchUserPointsAndReward = async () => {
      setLoading(true);
      try {
        const email = localStorage.getItem("email"); 
        if (!email) {
          setError("Email not found in localStorage");
          setLoading(false);
          return;
        }

        // Fetch user data from Firestore
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const userData = usersSnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .find((user) => user.email === email);

        if (!userData) {
          setError("User not found");
          setLoading(false);
          return;
        }

        const points = userData.score || 0;
        setUserPoints(points);

        // Fetch rewards and match the user's points
        const rewardsCollection = collection(db, "rewards");
        const rewardsSnapshot = await getDocs(rewardsCollection);
        const rewardsList = rewardsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        
        const matchedReward = rewardsList.find(
          (reward) =>
            points >= reward.pointsGreaterThan &&
            (reward.pointsLessThan === undefined ||
              points < reward.pointsLessThan)
        );

        if (matchedReward) {
          setReward(matchedReward.reward);
        } else {
          setReward("No rewards available for your points");
        }

        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        console.error(err);
        setLoading(false);
      }
    };

    fetchUserPointsAndReward();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "80%",
          color: "white",
          fontFamily: "GTLight",
          backgroundColor: "rgba(202,46,50,229)",
          // backgroundImage: `url(${formBgImage})`,
          // backgroundSize: "cover",
          padding: "20px",
          borderRadius: "30px",
          backgroundPosition: "center",
        }}
      >
        {/* <h2 >User Rewards</h2> */}
        {/* <h3>You have completed all tasks and earned </h3>
  <h2>{scoreToAdd} POINTS</h2> */}

        <h1></h1>
        {userPoints !== null && (
          <h2>
            Your Points: <strong>{userPoints}</strong>
          </h2>
        )}
        {reward && (
          <h2>
            Your Reward : <strong>{reward}</strong>
          </h2>
        )}
      </div>
      <button
    className="go-back-button"
    style={{
      marginTop: 20,
      backgroundColor: "rgba(202,46,50,229)",
      width: "80%",
      color: "white",
      borderRadius:30
    }}
    onClick={handleGoBack}
  >
    GO BACK
  </button>
    </div>
  );
}
