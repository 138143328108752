import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import "./Profile.css"; 
import pic from '../Assets/ss.png'
import { useNavigate } from "react-router-dom";



const db = getFirestore();

const Profile = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    nationality: "",
  });
  const navigate = useNavigate()
 
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userEmail = localStorage.getItem("email"); 
        if (!userEmail) {
          console.error("No email found in localStorage");
          return;
        }

        const usersQuery = query(
          collection(db, "users"),
          where("email", "==", userEmail)
        );

        const querySnapshot = await getDocs(usersQuery);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setUserDetails({
            name: userData.name || "",
            email: userData.email || "",
            mobile: userData.mobileNumber || "",
            nationality: userData.nationality || "",
          });
        } else {
          console.error("User not found in Firestore");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="profile-container" style={{flexDirection:'column',fontFamily:'GTRegular', }}>
          <h2 style={{color:'white',marginBottom:90}}>MY PROFILE</h2>
      <div className="profile-card" style={{  backgroundColor: "rgba(202,46,50,229)",
          padding: "10px",

          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "80%", // Use percentages for better responsiveness
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center elements horizontally
      }}
          >
      
        <div className="profile-picture">
          <img
            src={pic}
            alt="Profile"
          />
        </div>
        
        <div style={inputContainerStyle}>
          <label style={{...labelStyle, marginTop:40}}>NAME</label>
        {/* <div className="profile-field" style={{marginTop:35}}>
          <label>NAME</label> */}
          <input type="text" value={userDetails.name} readOnly   style={inputStyle}/>
        </div>

        
        <div style={inputContainerStyle}>
          <label style={labelStyle}>EMAIL</label>
        {/* <div className="profile-field">
          <label>EMAIL</label> */}
          <input type="text" value={userDetails.email} readOnly    style={inputStyle}/>
        </div>
        
        <div style={inputContainerStyle}>
          <label style={labelStyle}>MOBILE NO.</label>
        {/* <div className="profile-field">
          <label>MOBILE NO.</label> */}
          <input type="text" value={userDetails.mobile} readOnly   style={inputStyle} />
        </div>
        
        <div style={inputContainerStyle}>
          <label style={labelStyle}>NATIONALITY</label>
        {/* <div className="profile-field">
          <label>NATIONALITY</label> */}
          <input type="text" value={userDetails.nationality} readOnly   style={inputStyle} />
        </div>
        <p onClick={()=>{navigate('/reset')}}>CHANGE PASSWORD</p>
        <button className="go-back-button" style={{ marginTop:10,backgroundColor:'white',width:'80%',color:'red',padding:'20',borderRadius:30,marginBottom:20}} onClick={handleGoBack}>
            GO BACK
          </button>
      </div>
     
    </div>
  );
};




const inputContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  width: "90%",
  alignItems: "center", // Center child elements
  textAlign: "center", // Center text inside labels and inputs
};

const labelStyle = {
  color: "white",
  marginBottom: "5px",
  fontSize: "14px",
  textAlign: "left", // Keep the label text aligned left
  width: "100%", // Ensure the label spans the width of the container
};

const inputStyle = {
  width: "100%",
  boxSizing: "border-box", // Include padding in the width calculation
  border: "1px solid white",
  borderRadius: "35px",
  backgroundColor: "transparent",
  color: "white",
  outline: "none",
  padding: "10px 15px", // Add horizontal padding for better text positioning
 // textAlign: "left", // Ensure input text starts from the left
};
export default Profile;
