import React from "react";
import bg from "../Assets/Page1/bg.jpg";
import logo from "../Assets/Page1/logo.png";
import { useNavigate } from "react-router-dom";

export default function Start() {

    const navigate = useNavigate();

    const buttonClicked =()=>{

        navigate('/signup')
    }


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundSize: "cover",
        minHeight: "100vh",
        justifyContent: "center",
        minWidth:'100vw',
        width:'100vw',
        maxWidth:'100vw',
        maxHeight:'100vh',

        backgroundImage: `url(${bg})`,
        fontFamily:'GTRegular'
      }}
    >
      <img
        src={logo}
        style={{ width: "80%", position: "absolute", top: "10%" }}
      ></img>
      <button
        style={{
          background: "rgba(196, 22, 28, 0.9)", 
          color: "white",
          fontWeight: "bold",
          position: "fixed",
          bottom: "5vh",
          width: "80%",
          borderRadius: 50,
          padding: '10px',
          borderColor: "transparent",
           fontSize: 18,
        }}

        onClick={buttonClicked}


      >
        LET'S  GO{" "}
      </button>
    </div>
  );
}
