// import React, { useState, useEffect } from "react";
// import { getFirestore, collection, onSnapshot } from "firebase/firestore";
// import * as XLSX from "xlsx"; // To export Excel

// const db = getFirestore();

// const UserListPage = () => {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     // Fetch users in real-time
//     const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
//       const fetchedUsers = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setUsers(fetchedUsers);
//     });

//     return () => unsubscribe();
//   }, []);

//   // Export users data to Excel
//   const exportToExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(users); // Convert users data to worksheet
//     const wb = XLSX.utils.book_new(); // Create a new workbook
//     XLSX.utils.book_append_sheet(wb, ws, "Users"); // Append the worksheet to the workbook
//     XLSX.writeFile(wb, "users_data.xlsx"); // Download the Excel file
//   };

//   return (
//     <div style={{ maxWidth: "80%", margin: "0 auto", padding: "20px",backgroundColor:'white' }}>
//       <h1 style={{ textAlign: "center", color: "#333" }}>Users List</h1>

//       {/* Table to display users */}
//       <table
//         style={{
//           width: "100%",
//           borderCollapse: "collapse",
//           marginBottom: "20px",
//           border: "1px solid #ddd",
//         }}
//       >
//         <thead>
//           <tr style={{ backgroundColor: "#f8f9fa", textAlign: "left" }}>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>Name</th>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>Email</th>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>
//               Nationality
//             </th>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>
//               Phone Number
//             </th>
//             <th style={{ padding: "10px", border: "1px solid #ddd" }}>Points</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.map((user) => (
//             <tr key={user.id}>
//               <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                 {user.name}
//               </td>
//               <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                 {user.email}
//               </td>
//               <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                 {user.nationality}
//               </td>
//               <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                 {user.phoneNumber}
//               </td>
//               <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                 {user.score}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Export button */}
//       <div style={{ textAlign: "center" }}>
//         <button
//           onClick={exportToExcel}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           Export Excel Sheet of Users
//         </button>
//       </div>
//     </div>
//   );
// };

// export default UserListPage;














import React, { useState, useEffect } from "react";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import * as XLSX from "xlsx"; // To export Excel

const db = getFirestore();

const UserListPage = () => {
  const [users, setUsers] = useState([]);
  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    // Fetch users in real-time
    const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
      const fetchedUsers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(fetchedUsers);
    });

    return () => unsubscribe();
  }, []);

  // Export users data to Excel
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(users); // Convert users data to worksheet
    const wb = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(wb, ws, "Users"); // Append the worksheet to the workbook
    XLSX.writeFile(wb, "users_data.xlsx"); // Download the Excel file
  };

  return (
    <div style={{ maxWidth: "80%", margin: "0 auto", padding: "20px", backgroundColor: 'white' }}>
      <h1 style={{ textAlign: "center", color: "#333" }}>Users List</h1>

      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" ,flexDirection:'column'}}>
        <button
          onClick={exportToExcel}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Export Excel Sheet of Users
        </button>
      </div>

      <div style={{ display: 'flex', height: '80%', overflowY: 'auto' }}>
        <table
          style={{
            width: "100%",
            placeItems:'center',
            borderCollapse: "collapse",
            marginBottom: "20px",
            maxHeight: "500px",
            overflowY: "auto", // Enables vertical scrolling
            display: "block",  // Makes the table a block-level element to allow scrolling
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#f8f9fa", textAlign: "left" }}>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>First Name</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Surame</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Email</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Nationality</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Phone Number</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Time Taken</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>{user.name}</td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>{user.surname}</td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>{user.email}</td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>{user.nationality}</td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>{user.mobileNumber}</td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>{user.elapsedTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className="go-back-button" style={{ marginTop:0, backgroundColor:'red',color:'white'}} onClick={handleGoBack}>
            GO BACK
          </button>
    </div>
  );
};

export default UserListPage;
