


// import React, { useState, useEffect, useContext } from "react";
// import { getFirestore, collection, getDocs } from "firebase/firestore";
// import { useNavigate } from "react-router-dom";
// import { TimerContext } from "../TimerContext";
// import "./TreasureHunting.css";

// const db = getFirestore();

// const TreasureHunting = () => {
//   const { elapsedTime } = useContext(TimerContext); // Access elapsed time from context
//   const [tasks, setTasks] = useState([]);
//   const navigate = useNavigate();
//   const correctQRs = ["QR-1", "QR-2", "QR-3", "QR-4", "QR-5", "QR-6"];

//   useEffect(() => {
//     const fetchRandomTasks = async () => {
//       const savedTasks = JSON.parse(localStorage.getItem("randomTasks"));
//       const taskStatuses = JSON.parse(localStorage.getItem("taskStatuses")) || {};

//       if (savedTasks) {
//         const updatedSavedTasks = savedTasks.map((task, index) => {
//           const statusKey = `task_${index + 1}_Status`;
//           return {
//             ...task,
//             status: taskStatuses[statusKey] || "Pending",
//           };
//         });
//         setTasks(updatedSavedTasks);
//         return;
//       }

//       const querySnapshot = await getDocs(collection(db, "tasks"));
//       const fetchedTasks = querySnapshot.docs.map((doc) => doc.data());

//       const shuffledTasks = fetchedTasks.sort(() => Math.random() - 0.5).slice(0, 6);

//       const updatedTasks = shuffledTasks.map((task, index) => {
//         const statusKey = `task_${index + 1}_Status`;
//         return {
//           ...task,
//           status: taskStatuses[statusKey] || "Pending",
//         };
//       });

//       setTasks(updatedTasks);
//       localStorage.setItem("randomTasks", JSON.stringify(updatedTasks));
//     };

//     fetchRandomTasks();
//   }, []);

//   const formatTime = (time) => {
//     const hours = Math.floor(time / 3600).toString().padStart(2, "0");
//     const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, "0");
//     const seconds = (time % 60).toString().padStart(2, "0");
  
//     return hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
//   };
  

//   const updateTaskStatus = (index, newStatus) => {
//     const updatedTasks = [...tasks];
//     updatedTasks[index].status = newStatus;
//     setTasks(updatedTasks);

//     const taskStatuses = JSON.parse(localStorage.getItem("taskStatuses")) || {};
//     taskStatuses[`task_${index + 1}_Status`] = newStatus; // Use consistent key format
//     localStorage.setItem("taskStatuses", JSON.stringify(taskStatuses));
//   };



//   const taskColors = [
//     "rgba(196, 22, 28, 0.9)",
//     "rgba(72,102,150,0.9)",  
//     "rgba(216,186,85,0.9)", 
//     "rgba(151,159,113,0.9)",  
//     "rgba(116,179,224,0.9)", 
//     "rgba(194,122,160,0.9)", 
   
//   ];
//   return (
//     <div className="treasure-hunting-container" style={{ fontFamily: "GTLight" }}>
//   <div className="heading" style={{ width: "100%" }}>
//     <p style={{ marginBottom: 5 }}>TIME ELAPSED</p>
//     {/* Display the formatted elapsed time */}
//     <h2 style={{ marginTop: 0, fontWeight: "bold" }}>{formatTime(elapsedTime)}</h2>
//   </div>

//   <div className="task-list" style={{bottom:130}}>
//     {tasks.map((task, index) => (
//       <div
//         className="task-item"
//         key={index}
//         style={{
//           backgroundColor:
//             task.status === "Completed"
//               ? "rgba(34, 139, 34, 0.9)" // Green for completed tasks
//               : taskColors[index % taskColors.length], // Cycle through predefined colors
//           padding: "10px",
//           borderRadius: "60px",
//         }}
//       >
//         <div className="task-number">{index + 1}</div>
//         <div className="vertical-line1"></div>
//         <div className="task-details">
//           <div className="task-name">
//             {task.taskName.split(" ").slice(0, 3).join(" ")}
//             {task.taskName.split(" ").length > 5 ? "..." : ""}
//           </div>
//           <p className="task-clue">
//             TASK STATUS -{" "}
//             <span style={{ fontWeight: "bold" }}>{task.status}</span>
//           </p>
//         </div>
//         <button
//           className="view-task-button"
//           onClick={() =>
//             navigate(`/task/${index}`, {
//               state: {
//                 task,
//                 correctQR: correctQRs[index],
//                 taskIndex: index,
//               },
//             })
//           }
//         >
//           <span>VIEW</span>
//           <span>TASK</span>
//         </button>
//       </div>
//     ))}
//   </div>
// </div>
    
//   );
// };

// export default TreasureHunting;



























import React, { useState, useEffect, useContext } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { TimerContext } from "../TimerContext";
import "./TreasureHunting.css";

const db = getFirestore();

const TreasureHunting = () => {
  const { elapsedTime } = useContext(TimerContext);
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate();
  const correctQRs = ["QR-1", "QR-2", "QR-3", "QR-4", "QR-5", "QR-6"];

  useEffect(() => {
    const fetchRandomTasks = async () => {
      const savedTasks = JSON.parse(localStorage.getItem("randomTasks"));
      const taskStatuses = JSON.parse(localStorage.getItem("taskStatuses")) || {};

      if (savedTasks) {
        const updatedSavedTasks = savedTasks.map((task, index) => {
          const statusKey = `task_${index + 1}_Status`;
          return {
            ...task,
            status: taskStatuses[statusKey] || "Pending",
          };
        });
        setTasks(updatedSavedTasks);
        return;
      }

      const querySnapshot = await getDocs(collection(db, "tasks"));
      const fetchedTasks = querySnapshot.docs.map((doc) => doc.data());

      // Group tasks by zones
      const groupedTasksByZone = {};
      fetchedTasks.forEach((task) => {
        if (!groupedTasksByZone[task.zone]) {
          groupedTasksByZone[task.zone] = [];
        }
        groupedTasksByZone[task.zone].push(task);
      });

      // Select a random task from each required zone
      const selectedTasks = [];
      for (let i = 1; i <= 6; i++) {
        if (groupedTasksByZone[i] && groupedTasksByZone[i].length > 0) {
          const randomTask = groupedTasksByZone[i][Math.floor(Math.random() * groupedTasksByZone[i].length)];
          selectedTasks.push({
            ...randomTask,
            status: "Pending",
          });
        }
      }

      setTasks(selectedTasks);
      localStorage.setItem("randomTasks", JSON.stringify(selectedTasks));
    };

    fetchRandomTasks();
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
  
    return hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
  };

  const updateTaskStatus = (index, newStatus) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].status = newStatus;
    setTasks(updatedTasks);

    const taskStatuses = JSON.parse(localStorage.getItem("taskStatuses")) || {};
    taskStatuses[`task_${index + 1}_Status`] = newStatus;
    localStorage.setItem("taskStatuses", JSON.stringify(taskStatuses));
  };

  const taskColors = [
    "rgba(196, 22, 28, 0.9)",
    "rgba(72,102,150,0.9)",
    "rgba(216,186,85,0.9)",
    "rgba(151,159,113,0.9)",
    "rgba(116,179,224,0.9)",
    "rgba(194,122,160,0.9)",
  ];

  return (
    <div className="treasure-hunting-container" style={{ fontFamily: "GTLight" }}>
      <div className="heading" style={{ width: "100%" }}>
        <p style={{ marginBottom: 5 }}>TIME ELAPSED</p>
        <h2 style={{ marginTop: 0, fontWeight: "bold" }}>{formatTime(elapsedTime)}</h2>
      </div>

      <div className="task-list" style={{ bottom: 130 }}>
        {tasks.map((task, index) => (
          <div
            className="task-item"
            key={index}
            style={{
              backgroundColor:
                task.status === "Completed"
                  ? "rgba(34, 139, 34, 0.9)"
                  : taskColors[index % taskColors.length],
              padding: "10px",
              borderRadius: "60px",
            }}
          >
            <div className="task-number">{index + 1}</div>
            <div className="vertical-line1"></div>
            <div className="task-details">
              <div className="task-name">
                {task.taskName.split(" ").slice(0, 3).join(" ")}
                {task.taskName.split(" ").length > 5 ? "..." : ""}
              </div>
              <p className="task-clue">
              TASK STATUS -{" "}
                         <span style={{ fontWeight: "bold" }}>{task.status}</span>
              </p>
            </div>
            <button
              className="view-task-button"
              onClick={() =>
                navigate(`/task/${index}`, {
                  state: {
                    task,
                    correctQR: correctQRs[index],
                    taskIndex: index,
                  },
                })
              }
            >
              <span>VIEW</span>
              <span>TASK</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TreasureHunting;
