import React, { useState } from "react";
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import bgImage from "../Assets/Page2/bg.jpg";
import formBgImage from "../Assets/Page2/s.png";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const db = getFirestore();

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
const navigate = useNavigate();
  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!email || !newPassword) {
      toast.error("Please enter both email and new password!");
      return;
    }

    try {
      // Normalize the email to lower case to prevent case sensitivity issues
      const normalizedEmail = email.toLowerCase();

      // Query the Firestore collection for a document where the email matches
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", normalizedEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // User exists, update password
        const userDoc = querySnapshot.docs[0]; // Get the first document from the query
        const userRef = doc(db, "users", userDoc.id); // Reference the document using its ID
        await updateDoc(userRef, { password: newPassword });
        toast.success("Password updated successfully!");
        localStorage.removeItem("email");
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } else {
        toast.error("Email does not exist.");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Error updating password. Please try again.");
    }
  };

  return (
    <div
      className="forgot-password"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth:'100vw',
        width:'100vw',
        maxWidth:'100vw',
        fontFamily:'GTRegular',
        flexDirection:'column'
      }}
    >
       <h2 style={{ color: "white", textAlign: "center" }}>Forgot Password</h2>
      <form
        style={{
          backgroundColor: "rgba(202,46,50,229)",
          padding: "25px",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "80%", // Use percentages for better responsiveness
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center elements horizontally
        }}
        onSubmit={handleResetPassword}
      >
          <div style={inputContainerStyle}>
          <label style={labelStyle}>EMAIL</label>
        <input
          type="email"
         // placeholder="Enter your email"
          style={inputStyle}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        </div>
        <div style={inputContainerStyle}>
        <label style={labelStyle}>PASSWORD</label>
        <input
          type="password"
         // placeholder="Enter new password"
          style={inputStyle}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
         </div>
        <button
          type="submit"
          style={{
            background: "white",
            color: "red",
            border: "none",
            borderRadius: "30px",
            padding: "10px 15px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "90%",
            marginBottom: "10px",
            marginTop:5,
          }}
        >
          RESET PASSWORD
        </button>
      </form>
    </div>
  );
};

const inputContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  width: "90%",
  alignItems: "center", // Center child elements
  textAlign: "center", // Center text inside labels and inputs
};

const labelStyle = {
  color: "white",
  marginBottom: "5px",
  fontSize: "14px",
  textAlign: "left", // Keep the label text aligned left
  width: "100%", // Ensure the label spans the width of the container
};

const inputStyle = {
  width: "100%",
  boxSizing: "border-box", // Include padding in the width calculation
  border: "1px solid white",
  borderRadius: "35px",
  backgroundColor: "transparent",
  color: "white",
  outline: "none",
  padding: "10px 15px", // Add horizontal padding for better text positioning
  textAlign: "left", // Ensure input text starts from the left
};

export default ForgotPassword;
