import React, { useState } from "react";
import { getFirestore, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import bgImage from "../Assets/Page2/bg.jpg";
import formBgImage from "../Assets/Page2/s.png";

const db = getFirestore();

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!newPassword || !confirmPassword) {
      setMessage("Fields cannot be empty.");
      return;
    }
    if (newPassword.length < 6) {
      setMessage("Password must be at least 6 characters long.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    const email = localStorage.getItem("email");
    if (!email) {
      setMessage("No email found. Please log in again.");
      return;
    }

    try {
      // Query the collection to find the document with the matching email
      const usersCollection = collection(db, "users");
      const emailQuery = query(usersCollection, where("email", "==", email));
      const querySnapshot = await getDocs(emailQuery);

      if (querySnapshot.empty) {
        setMessage("User not found. Please check the email or contact support.");
        return;
      }

      // Assuming only one document matches the email
      const userDoc = querySnapshot.docs[0];
      const userDocRef = userDoc.ref;

      // Update the password field
      await updateDoc(userDocRef, { password: newPassword });
      setMessage("Password reset successfully!");
      localStorage.removeItem("email");
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } catch (error) {
      console.error("Error resetting password:", error);
      setMessage("Error resetting password. Please try again.");
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "GTLight",
      }}
    >
      <form
        style={{
          backgroundColor: "rgba(202,46,50,229)",
          padding: "25px",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "80%", // Use percentages for better responsiveness
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center elements horizontally
        }}
        onSubmit={handleResetPassword}
      >
        <h2 style={{ color: "white", textAlign: "center" }}>Reset Password</h2>
        {message && (
          <p style={{ color: "black", textAlign: "center" }}>
            {message}
          </p>
        )}
        <div style={inputContainerStyle}>
        <label style={labelStyle}>NEW PASSWORD</label>
        <input
          type="password"
         // placeholder="New Password"
          style={inputStyle}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        </div>
        <div style={inputContainerStyle}>
          <label style={labelStyle}>CONFIRM PASSWORD</label>
        <input
          type="password"
         // placeholder="Confirm Password"
          style={inputStyle}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        </div>
        <button
          type="submit"
          style={{
            background: "white",
            color: "red",
            border: "none",
            borderRadius: "15px",
            padding: "10px 15px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "90%",
          }}
        >
          RESET PASSWORD
        </button>
      </form>
    </div>
  );
};

const inputContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  width: "90%",
  alignItems: "center", // Center child elements
  textAlign: "center", // Center text inside labels and inputs
};

const labelStyle = {
  color: "white",
  marginBottom: "5px",
  fontSize: "14px",
  textAlign: "left",
  width: "100%", 
};

const inputStyle = {
  width: "100%",
  boxSizing: "border-box", 
  border: "1px solid white",
  borderRadius: "35px",
  backgroundColor: "transparent",
  color: "white",
  outline: "none",
  padding: "10px 15px", // Add horizontal padding for better text positioning
  textAlign: "left", // Ensure input text starts from the left
};
export default ResetPassword;
