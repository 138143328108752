
//Perfectly working only issue when reload and become zero

// import React, { createContext, useState, useEffect } from "react";

// export const TimerContext = createContext();



// export const TimerProvider = ({ children }) => {
//   const [startTime, setStartTime] = useState(null);
//   const [elapsedTime, setElapsedTime] = useState(0);
//   const [timerActive, setTimerActive] = useState(false);

//   useEffect(() => {
//     let interval;
//     if (timerActive) {
//       interval = setInterval(() => {
//         if (startTime) {
//           const now = new Date().getTime();
//           setElapsedTime(Math.floor((now - startTime) / 1000)); 
//         }
//       }, 1000);
//     }
//     return () => clearInterval(interval);
//   }, [timerActive, startTime]);

//   const startTimer = () => {
//     setStartTime((prev) => prev || new Date().getTime()); 
//     setTimerActive(true);
//   };

//   const stopTimer = () => {
//     setTimerActive(false);
//   };

//   const resetTimer = () => {
//     setStartTime(null);
//     setElapsedTime(0);
//     setTimerActive(false);
//   };

//   return (
//     <TimerContext.Provider
//       value={{
//         elapsedTime,
//         startTimer,
//         stopTimer,
//         resetTimer,
//       }}
//     >
//       {children}
//     </TimerContext.Provider>
//   );
// };










import React, { createContext, useState, useEffect } from "react";

export const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [startTime, setStartTime] = useState(() => {
    const savedStartTime = localStorage.getItem("startTime");
    return savedStartTime ? parseInt(savedStartTime, 10) : null;
  });

  const [elapsedTime, setElapsedTime] = useState(() => {
    const savedStartTime = localStorage.getItem("startTime");
    if (savedStartTime) {
      const now = new Date().getTime();
      return Math.floor((now - parseInt(savedStartTime, 10)) / 1000);
    }
    return 0;
  });

  const [timerActive, setTimerActive] = useState(() => {
    return !!localStorage.getItem("startTime"); // If there's a startTime, the timer is active
  });

  useEffect(() => {
    let interval;
    if (timerActive && startTime) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        setElapsedTime(Math.floor((now - startTime) / 1000));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerActive, startTime]);

  const startTimer = () => {
    const now = new Date().getTime();
    if (!startTime) {
      localStorage.setItem("startTime", now.toString()); // Save start time to localStorage
      setStartTime(now);
    }
    setTimerActive(true);
  };

  const stopTimer = () => {
    setTimerActive(false);
  };

  const resetTimer = () => {
    localStorage.removeItem("startTime"); // Remove the saved start time from localStorage
    setStartTime(null);
    setElapsedTime(0);
    setTimerActive(false);
  };

  return (
    <TimerContext.Provider
      value={{
        elapsedTime,
        startTimer,
        stopTimer,
        resetTimer,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

