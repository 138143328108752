import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import bgImage from "../Assets/Page2/bg.jpg";
import formBgImage from "../Assets/Page2/s.png"; // Form background image
import {resetAttemptsOnLogin} from '../firebase'
import { TimerContext } from "../TimerContext";

const db = getFirestore();

const Login = () => {
  const {stopTimer} = useContext(TimerContext)
  // const {resetAttemptsOnLogin}= useContext(TimerContext)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleStopTimer=()=>{
    localStorage.removeItem('email')
    stopTimer()
   }
  // Check if user session exists on component mount
  useEffect(() => {
    const session = localStorage.getItem("userSession");
    if (session) {
      const sessionData = JSON.parse(session);
      const now = new Date().getTime();
      if (now - sessionData.timestamp < 3 * 60 * 60 * 1000) { // 2 hours
        // Redirect based on terms acceptance
        if (sessionData.termsAccepted) {
          navigate("/dashboard");
        } else {
          navigate("/terms");
        }
      } else {
        localStorage.removeItem("userSession"); // Expire session after 2 hours
      }
    }
  }, [navigate]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      // Query Firestore to find the user by email
      const userCollection = collection(db, "users");
      const q = query(userCollection, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setErrorMessage("User not found");
        return;
      }

      const userData = querySnapshot.docs[0].data();

      // Match password
      if (userData.password !== password) {
        setErrorMessage("Invalid email or password");
        return;
      }


      await resetAttemptsOnLogin(email);
      // Save session to localStorage
      const sessionData = {
        email: userData.email,
        name: userData.name,
        timestamp: new Date().getTime(),
        termsAccepted: userData.termsChecked // Assuming this is the field to check 
      };
      localStorage.setItem("userSession", JSON.stringify(sessionData)); // Save the session
      // localStorage.removeItem("userSession");
      localStorage.removeItem("randomTasks");
      localStorage.removeItem("taskStatuses");
      localStorage.removeItem("timeLeft"); 
      localStorage.removeItem('startTime');
      handleStopTimer();
      console.log("Login successful!");
      if (userData.termsChecked) {
        navigate("/dashboard");
      } else {
        navigate("/terms");
      }
      localStorage.setItem('email',email )
      // navigate("/terms"); 
    } catch (error) {
      console.error("Error during login: ", error);
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily:'GTRegular'
      }}
    >
      <form
        //className="login-form"
        style={{
          backgroundColor: "rgba(202,46,50,229)",
          padding: "25px",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "80%", // Use percentages for better responsiveness
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent:'center',
          alignItems: "center", // Center elements horizontally
        }}
        onSubmit={handleSubmit}
      >
        {/* <h2 style={{ color: "white", textAlign: "center", marginBottom: "20px" }}>
          Login
        </h2> */}
        {errorMessage && (
          <p style={{ color: "black", textAlign: "center" }}>{errorMessage}</p>
        )}
  
     
     <div style={inputContainerStyle}>
          <label style={labelStyle}>EMAIL</label>
          <input
           type="email"
           name="email"
          //  placeholder="Email"
           style={inputStyle}
           value={email}
           onChange={(e) => setEmail(e.target.value)}
           required
          />
        </div>

<div style={inputContainerStyle}>
          <label style={labelStyle}>PASSWORD</label>
          <input
            type="password"
            name="password"
            // placeholder="Password"
            style={inputStyle}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
  
        <button
          type="submit"
          style={{
            background: "white",
            color: "red",
            border: "none",
            borderRadius: "30px",
            padding: "10px 15px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "90%",
            marginBottom: "10px",
            marginTop:5,
          }}
        >
          S U B M I T
        </button>
  
        <div style={{ color: "white", width:'85%',textAlignLast:'left' ,placeSelf:'center'}}>
        <p style={{ color: "white",fontWeight:'bold' }}>
          Forgot{" "}
          <span
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigate("/forgotpassword")}
          >
            Password?
          </span>
        </p>
        </div>
      </form>
    </div>
  );
};

const inputContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  width: "90%",
  alignItems: "center", // Center child elements
  textAlign: "center", // Center text inside labels and inputs
};

const labelStyle = {
  color: "white",
  marginBottom: "5px",
  fontSize: "14px",
  textAlign: "left", // Keep the label text aligned left
  width: "100%", // Ensure the label spans the width of the container
};

const inputStyle = {
  width: "100%",
  boxSizing: "border-box", // Include padding in the width calculation
  border: "1px solid white",
  borderRadius: "35px",
  backgroundColor: "transparent",
  color: "white",
  outline: "none",
  padding: "10px 15px", // Add horizontal padding for better text positioning
  textAlign: "left", // Ensure input text starts from the left
};

export default Login;









