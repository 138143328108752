import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postUserData } from "../firebase"; // Replace with your Firebase function
import bgImage from "../Assets/Page2/bg.jpg"; 
import formBgImage from "../Assets/Page2/s.png";
import toast from "react-hot-toast";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    nationality: "",
    password: "",
    surname:"",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("userSession");
    localStorage.removeItem("email");
    localStorage.removeItem("randomTasks");
    localStorage.removeItem("taskStatuses");
    localStorage.removeItem("timeLeft");

    try {
      const response = await postUserData(formData); 
      if (response.success) {
        toast.success("User registered successfully!");
        navigate("/login");
      } else if (response.message === "Email already exists") {
        toast.error("Email already registered. You can login directly!");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during registration: ", error);
      toast.error("An error occurred during registration. Please try again.");
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   localStorage.removeItem("userSession");
  //   localStorage.removeItem("email");
  //   localStorage.removeItem("randomTasks");
  //   localStorage.removeItem("taskStatuses");
  //   localStorage.removeItem("timeLeft"); 
  //   try {
  //     await postUserData(formData);
  //     console.log("User registered successfully!");
  //     navigate("/login");
  //   } catch (error) {
  //     console.error("Error during registration: ", error);
  //   }
  // };

  return (
    <div
      className="signup-container"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth:'100vw',
        width:'100vw',
        maxWidth:'100vw',
        fontFamily:'GTRegular'
      }}
    >
      <form
        // className="signup-form"
        style={{
          backgroundColor: "rgba(202,46,50,229)",
          padding: "25px",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "80%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onSubmit={handleSubmit}
      >
        {/* <h2 style={{ color: "white", textAlign: "center", marginBottom: "20px" }}>
          Sign Up
        </h2> */}

        {/** Input with Label */}
        <div style={inputContainerStyle}>
          <label style={labelStyle}>FIRST NAME</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            style={inputStyle}
            required
          />
        </div>
        <div style={inputContainerStyle}>
          <label style={labelStyle}>SURNAME</label>
          <input
            type="text"
            name="surname"
            value={formData.surname}
            onChange={handleInputChange}
            style={inputStyle}
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle}>EMAIL</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            style={inputStyle}
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle}>MOBILE NUMBER</label>
          <input
            type="tel"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleInputChange}
            style={inputStyle}
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle}>NATIONALITY</label>
          <input
            type="text"
            name="nationality"
            value={formData.nationality}
            onChange={handleInputChange}
            style={inputStyle}
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle}>PASSWORD</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            style={inputStyle}
            required
          />
        </div>

        <button
          type="submit"
          style={{
            background: "white",
            color: "red",
            border: "none",
            borderRadius: "30px",
            padding: "10px 15px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "90%",
            marginBottom: "10px",
            marginTop:5,
            // fontWeight:'GTRegular'
          }}
        >
          S U B M I T
        </button>
        <p style={{ color: "white", textAlign: "center" }}>
          Already registered?{" "}
          <span
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigate("/login")}
          >
            Login Here
          </span>
        </p>
      </form>
    </div>
  );
};

// const inputContainerStyle = {
//   display: "flex",
//   flexDirection: "column",
//   marginBottom: "10px",
//   justifySelf:'center',
//   width:'90%'
// };

// const labelStyle = {
//   color: "white",
//   marginBottom: "5px",
//   fontSize: "14px",
//   textAlignLast: 'left'
// };

// const inputStyle = {
//   width: "100%",
//   //  padding: "10px",
//   border: "1px solid white",
//   borderRadius: "35px",
//   backgroundColor: "transparent",
//   color: "white",
//   outline: "none",
//   padding: "10px 0px"
// };

const inputContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  width: "90%",
  alignItems: "center", // Center child elements
  textAlign: "center", // Center text inside labels and inputs
};

const labelStyle = {
  color: "white",
  marginBottom: "5px",
  fontSize: "14px",
  textAlign: "left", // Keep the label text aligned left
  width: "100%", // Ensure the label spans the width of the container
};

const inputStyle = {
  width: "100%",
  boxSizing: "border-box", // Include padding in the width calculation
  border: "1px solid white",
  borderRadius: "35px",
  backgroundColor: "transparent",
  color: "white",
  outline: "none",
  padding: "10px 15px", // Add horizontal padding for better text positioning
  textAlign: "left", // Ensure input text starts from the left
};

export default SignUp;













// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { postUserData } from "../firebase"; // I
// import bgImage from "../Assets/Page2/bg.png"; // 
// import formBgImage from "../Assets/Page2/s.png"; // 



// const SignUp = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     mobileNumber: "",
//     nationality: "",
//     password: "",
//   });

//   const navigate = useNavigate();

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       await postUserData(formData);
//       console.log("User registered successfully!");
      
//       navigate("/login");
//     } catch (error) {
//       console.error("Error during registration: ", error);
//     }
//   };

//   return (
//     <div
//       className="signup-container"
//       style={{
//         backgroundImage: `url(${bgImage})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         minHeight: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <form
//         className="signup-form"
//         style={{
//           backgroundImage: `url(${formBgImage})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           padding: "20px",
//           borderRadius: "10px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           width: "80%",
//           maxWidth: "400px",
//         }}
//         onSubmit={handleSubmit}
//       >
//         <h2 style={{ color: "white", textAlign: "center", marginBottom: "20px" }}>
//           Sign Up
//         </h2>
//         <span style={{minWidth:'80%',backgroundColor:'green'}}></span>
//         <label >Name</label>
//         <input
//           type="text"
//           name="name"
//           // placeholder="Name"
//           style={inputStyle}
//           value={formData.name}
//           onChange={handleInputChange}
//           required
//         />
//         <input
//           type="email"
//           name="email"
//           // placeholder="Email"
//           style={inputStyle}
//           value={formData.email}
//           onChange={handleInputChange}
//           required
//         />
//         <input
//           type="tel"
//           name="mobileNumber"
//           // placeholder="Mobile Number"
//           style={inputStyle}
//           value={formData.mobileNumber}
//           onChange={handleInputChange}
//           required
//         />
//         <input
//           type="text"
//           name="nationality"
//           // placeholder="Nationality"
//           style={inputStyle}
//           value={formData.nationality}
//           onChange={handleInputChange}
//           required
//         />
//         <input
//           type="password"
//           name="password"
//           // placeholder="Password"
//           style={inputStyle}
//           value={formData.password}
//           onChange={handleInputChange}
//           required
//         />
//         <button
//           type="submit"
//           style={{
//             background: "white",
//             color: "red",
//             border: "none",
//             borderRadius: "5px",
//             padding: "10px 15px",
//             cursor: "pointer",
//             fontWeight: "bold",
//             width: "100%",
//             marginBottom: "10px",
//           }}
          
//         >

//           Submit
//         </button>
//         <p style={{ color: "white", textAlign: "center" }}>
//           Already registered?{" "}
//           <span
//             style={{ color: "blue", cursor: "pointer" }}
//             onClick={() => navigate("/login")}
//           >
//             Login
//           </span>
//         </p>
//       </form>
//     </div>
//   );
// };


// const inputStyle = {
//   width: "80%",
//   padding: "10px",
//   marginBottom: "15px",
//   border: "1px solid white",
//   borderRadius: "5px",
//   backgroundColor: "transparent",
//   color: "white",
//   outline: "none",
// };

// export default SignUp;
